import { Dayjs } from "dayjs";
import { User } from "../users/types";
import { EmploymentStatusType } from "../utilities/types";

export type WorkExperiencePayload = {
  company_name: string;
  position: string;
  start_date: string | Dayjs;
  end_date: string | Dayjs | undefined;
  description: string;
};

export type WorkExperience = WorkExperiencePayload & {
  id: number;
};

export type PortfolioPayload = {
  title: string;
  description: string;
  url: string;
  image: string;
};

export type Portfolio = PortfolioPayload & {
  id: number;
};

export const WorkExperienceLevel = {
  Почетник: 0,
  Junior: 1,
  Intermediate: 2,
  Senior: 3,
} as const;

export type Service = {
  name: string;
  description?: string;
  price: number;
};

export type Skill = {
  name: string;
};

export type PostInfo = {
  id: number;
  description: string;
  phone: string;
  email: string;
  experience_years: number;
  work_experience_level: (typeof WorkExperienceLevel)[keyof typeof WorkExperienceLevel];
  salary: number;
  salary_currency: string;
  employment_status: number;
  current_company: string;
  current_company_position: string;
  current_company_description: string;
  current_company_start_date: string | Dayjs;
  current_company_end_date: string | Dayjs | undefined;
  region_id: number;
  category_id?: number;
  custom_category?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  github?: string;
  hide_email: boolean;
  hide_first_name: boolean;
  hide_last_name: boolean;
  hide_phone: boolean;
  hide_avatar: boolean;
  hide_birthday: boolean;
  // hide_gender: boolean;
  hide_employment_status: boolean;
  hide_current_company: boolean;
  skills: Skill[];
  services: Service[];
  work_experiences: WorkExperiencePayload[];
  portfolios: PortfolioPayload[];
  employment_type: EmploymentStatusType;
};

export type Post = Omit<PostInfo, "portfolios" | "work_experiences"> & {
  created_at: string;
  updated_at: string;
  user?: User;
  user_id: number;
  portfolios?: Portfolio[];
  workExperiences?: WorkExperience[];
  title?: string;
  categoryTitle: string;
};

export type CreatePostPayload = PostInfo;
export type CreatePostResponse = Post;

export type SearchPostsPayload = {
  page?: number;
  per_page?: number;
  category_id?: string;
  region_id?: string;
  s?: string;
  salary?: string;
  experience?: string;
  job_type_id?: string;
  has_portfolio?: string;
  has_services?: string;
};

export type SearchPostsResponse = Post[];

export type FilterAggregation = {
  value: number;
  value_as_string: string;
};


export type FiltersResponse = {
  max_salary: FilterAggregation;
  min_salary: FilterAggregation;
  max_experience: FilterAggregation;
  min_experience: FilterAggregation;
}
