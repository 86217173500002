import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import PageContainer from "../../components/common/PageContainer";
import { LoginContainer, LoginForm, LoginTextField, LoginButton } from "./styled";
import SocialLoginButtons from "./components/SocialLoginButtons";
import { NavLink, useNavigate } from "react-router-dom";
import { getEmailError, getPasswordError } from "./validation";
import { useLogin } from "../../hooks/auth/useLogin";
import { useUser } from "../../hooks/user/useUser";
import useGetConnections from "../../hooks/connections/useGetConnections";

type LoginFormInputs = {
  email: string;
  password: string;
};

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  const { user, refetch: reloadUser } = useUser();
  const { refetch: reloadConnections } = useGetConnections();
  const login = useLogin();
  const navigate = useNavigate();

  if (user?.id) {
    navigate(!user?.profile_complete ? "/profile" : "/");
  }

  const onSubmit = (data: LoginFormInputs) => {
    login.mutate(data, {
      onSuccess: async () => {
        await reloadUser();
        await reloadConnections();
      },
    });
  };

  return (
    <PageContainer py="0">
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" fontWeight={600}>
            TalentiMK
          </Typography>
          <Typography variant="body1">
            Најави се со твојот профил за да добиеш пристап до сите содржини на нашиот сајт, да
            објавуваш коментари или да го уредуваш твојот профил.
          </Typography>
          <LoginTextField
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            label="Email адреса"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.email}
            helperText={getEmailError(errors.email)}
          />
          <LoginTextField
            {...register("password", { required: true })}
            label="Лозинка"
            variant="outlined"
            margin="normal"
            type="password"
            fullWidth
            error={!!errors.password}
            helperText={getPasswordError(errors.password, false)}
          />
          <Typography variant="subtitle1">
            Ја заборави твојата лозинка?{" "}
            <Typography
              component={NavLink}
              to="/forgot-password"
              variant="subtitle1"
              color="primary"
            >
              Ресетирај ја
            </Typography>
          </Typography>
          <LoginButton type="submit" variant="contained" color="primary">
            Најави се
          </LoginButton>
          <SocialLoginButtons />

          <Typography variant="subtitle1">Немаш профил?</Typography>
          <Typography component={NavLink} to="/register" variant="subtitle1" color="primary">
            Регистрирај се
          </Typography>
        </LoginForm>
      </LoginContainer>
    </PageContainer>
  );
};

export default Login;
