import { Navigate } from "react-router";
import { useUser } from "../../hooks/user/useUser";
import { useEmploymentStatusTypes } from "../../hooks/utils/useEmploymentStatusTypes";
import { useRegions } from "../../hooks/utils/useRegions";
import ProfileForm from "./Form";
import PageContainer from "../../components/common/PageContainer";
import { CircularProgress } from "@mui/material";

const Profile = () => {
  const { user, isLoading: userLoading } = useUser();

  const { data: regions, isLoading: regionsLoading } = useRegions();


  if (userLoading || regionsLoading) {
    return (
      <PageContainer>
        <CircularProgress size={300} thickness={5} />
      </PageContainer>
    );
  }

  if (!user || !regions) {
    return <Navigate to="/login" />;
  }

  return (
    <ProfileForm
      user={user}
      regions={regions?.data}
    />
  );
};

export default Profile;
