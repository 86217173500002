import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import Button from "../../../../components/Button";
import { Search } from "@mui/icons-material";

const PopularSearchContainer = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: ${(props) => `${props.theme.spacing(3)} ${props.theme.spacing(2)}`};
  border: 2px solid transparent;
  box-sizing: border-box;
  width: 100%;
  transition: all 0.2s ease-in-out;
  text-align: center;
  background-color: #2fcd7112;
  border-radius: 10px;
  color: #153B4F;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: #2fcd7130;
  }
`;

const StyledButton = styled(Button)`
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const FooterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
`;

type PopularSearchProps = {
  label: string;
  value: string | number;
  totalSearches: number;
  description?: string;
  image?: string;
};

const PopularSearch = ({ label, value, totalSearches, description, image }: PopularSearchProps) => (
  <PopularSearchContainer to={"/posts?category_id=" + value}>
    {image && (
      <Box component="img" src={image} sx={{
        width: "30%",
        height: "auto",
        '@media (max-width: 1024px)': {
          width: "15%"
        }
      }
      } />
    )}
    <Typography variant="h5" color="primary.contrastText" fontWeight={600} textAlign='left' style={{
      fontSize: "20px"
    }}>
      {label}
    </Typography>
    {/* {description && (
      <Typography variant="body1" color="primary.contrastText">
        {description}
      </Typography>
    )} */}
  </PopularSearchContainer>
);

export default PopularSearch;
