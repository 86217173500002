import styled from "styled-components/macro";
import PageContainer from "../../components/common/PageContainer";
import { Typography } from "@mui/material";

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

const TermsOfService = () => (
  <PageContainer>
    <TermsContainer>
      <Typography variant="h2" color="primary">
        Услови за користење
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Корисници на Talenti.mk</Typography>
        Корисници на сајтот може да бидат полнолетни физички лица или компании. Физичките лица се
        зачленуваат со е-маил или Google сметка и доколку за забележи злоупотреба со лажни
        податоци сметката ќе биде избришана.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Огласи за идни вработувања</Typography> Огласите за идни
        вработувања или проекти ги објавуваат само физичките лица и Компаниите кои се зачленети на
        Порталот. Огласите треба да ги запазуваат позитивните законски норми а Друштвото може да ги
        измени или отстрани без претходна најава.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Информациите - Кратки биографии на корисниците</Typography>
        Информациите кои корисниците ги прикачуваат на сајтот Talenti.mk, или пак ги внесуваат, се
        видливи само за нив и за компаниите каде корисникот дал дозвола да се видливи. Доколку
        корисникот ја затвори сметката, сите податоци ќе бидат уништени/избришани.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Бришење на профил и кориснички податоци</Typography>
        Бришењето на профил креиран на Talenti.mk и сите кориснички податоци поврзани со него како и
        логови, се прави со пишување на „Барање за бришење на профил“ на info@talenti.mk од истиот
        е-маил од кој е креиран профилот.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Сопствена одговорност на содржината</Typography>
        Содржината која Корисниците ја внесуваат на сајтот мора да биде вистинита и нивна.
        Talenti.mk го задржува правато за промена, дополнување или бришење на било кој дел од
        страницата или на целата понуда без претходно известување, како и за времено или трајно
        запирање на објавата. Одговорноста за содржината на огласите, сликите, видео-клиповите,
        профилите или другите прилози лежи исклучиво кај корисниците. Talenti.mk не ја сноси
        одговорноста за потеклото на производот/услугата која е предмет на огласување.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Бришење на содржина</Typography>
        Корисникот може во било кое време сам да ја избрише содржината (оглас или др.) што ја има
        објавено на Talenti.mk.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Недопуштени содржини</Typography>
        Освен, забранетите содржини со закон, недозволиво е огласување со следнава содржина: <br />•
        Рекламирање на други интернет страници и фирми, особено веб страници за мали огласи,
        продавници за е-трговија, аукции на интернет. <br />• Очигледно несериозни и недоволно
        опишани огласи за работа. <br />• Натпревари, обложувања од секаков вид, вклучително и лото
        и други игри на среќа. <br />• Кредити, заеми и финансиска поддршка без целосен податок за
        домашната адреса на кредитната институција. <br />• Инвестиции, хартии од вредност, особено
        акции. <br />• Платени телефонски броеви како и СМС услуги. <br />• Навредливи, нападни или
        дискриминирачки изрази. <br />• Расистички, клеветнички, насилни или противуставни содржини.{" "}
        <br />• Нелегални огласи, кои го загрозуваат или повредуваат правото или здравјето на трета
        страна. <br />• Порнографија, вклучително порнографски и опасни за младината списи и
        предмети. <br />• Понуди за проституција. <br />• Контакт огласи во име на трета страна.{" "}
        <br />• Платени телефонски услуги или упатување на комерцијални еротски страници. <br />•
        Листи, папки или сл. кои ја содржат адресата на производителот, трговецот или снабдувачот.{" "}
        <br />• Огласи на странски јазик без македонски превод.
        <br />• Повеќекратно објавување оглас со идентична содржина – вклучително и во различни
        региони и рубрики. <br />• Огласи/предмети од различни категории/индустрии во еден оглас.{" "}
        <br />• Линкови кои не се поврзани со огласот/предметот на огласување. <br />• Сликите не
        смеат да содржат лого од компанија, телефонски број, веб страница и друга содржина која може
        да се толкува како маркетинг. <br />• Слики за кои корисникот нема авторски права.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Огласи кои не се во рамките нa правилата на talenti.mk и огласи кои не се не во рамките во
        согласност со законски прописи во Македонија нема да бидат објавени.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Недопуштени предмети</Typography>
        Недопуштени предмети како за понуда, така и за побарувачка се: <br />• Украдени,
        фалсификувани или неодобрено копирани предмети од било каков вид, тука се вбројуваат и
        пиратериски продукти. <br />• Лекови кои се издаваат на рецепт, фалсификувани лекови или
        лекови за кои постои основано сомнение дека се фалсификувани. <br />• Дроги и стимуланти во
        рамките на законските прописи. <br />• Радиоактивни супстанци, отрови, пиротехнички
        производи, експлозиви, како и други хемикалии штетни по здравјето. <br />• Човечки органи,
        крв и други телесни течности, како и носена долна облека. <br />• Оружје во поглед на
        законот на оружје, особено огнено оружје и оружје за сечење и прободување од било кој вид,
        како и муниција. <br />• Службени униформи и пасоши од безбедносни области. <br />• Продукти
        со ознаки на противуставни организации.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Авторизирање на Talenti.mk за преземање на содржини</Typography> За
        да не објавува ист оглас на повеќе различни сервиси за огласување, корисникот може да ја
        авторизира Talenti.mk да презема негови огласи кои се објавуваат на други веб сајтови за
        огласување, притоа корисникот гарантира дека огласите поврзани со неговата сметка се во
        согласност со Правилата на приватност на Talenti.mk и дека содржината е авторски негова
        (текст, слики и сл.). Корисникот во било кој момент може да ја прекине оваа опција.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Контакт преку Порталот</Typography>
        Податоците кои посетителите ги испраќаат доброволно преку е-маил и формуларот за контакт се
        сметаат за службена тајна и се чуваат во согласност со општите одредби и правилата и
        прописите за архивско работење. Тука спаѓаат и нивната адреса за е-пошта, име, презиме,
        поштенска адреса, телефон.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Наплата / Услови и правила на продажба</Typography>
        Цените на сите производи од Talenti.mk се во денари. Секој купувач најпрвин мора да се
        регистрира за да може да биде остварена нарачката. По завршувањето на нарачката ќе биде
        испратен e-mail на наведената адреса од страна на купувачот, за потврда на нарачката.
        <br />
        Купувањето (наплатата) може да се оствари преку банкарска трансакција или електронски со
        следните платежни картички: Visa; Visa Electron; Mastercard; Maestro (само доколку има
        CVV2/CVC код на позадината од картичката) а наплатата се извршува преку нашиот партнер НЛБ
        Тутунска Банка. <br />
        Активирањето на услугата која ја имате платено ќе биде остварена најкасно за еден работен
        ден. <br />
        Уплатените средства не се враќаат, но може да се пренаменат за друг оглас во иднина доколку
        навремено реагирате во наредните 30 минути на info@talenti.mk или на e-mail info@talenti.mk.
      </Typography>
    </TermsContainer>
  </PageContainer>
);

export default TermsOfService;
