import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Menu, MenuItem, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import useBreakpoints from "../../hooks/utils/useBreakpoints";

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1080px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  transition: filter 0.05s ease-in-out;
  box-sizing: border-box;
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: 1080px) {
    position: absolute;
    left: 5px;
  }
`;

const MyProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  height: 65px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  border-radius: 5px;

  @media (max-width: 767px) {
    height: 50px;
  }
`;

type ProfileButtonProps = {
  avatarUrl?: string;
  onLogout: () => void;
  onClick?: () => void;
};

const ProfileButton = ({ avatarUrl, onLogout, onClick }: ProfileButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  const { breakpoint } = useBreakpoints();

  const handleProfileImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    onLogout();
    handleMenuClose();
  };

  useEffect(() => {
    handleMenuClose();
  }, [location]);

  return (
    <Container onClick={onClick}>
      {breakpoint === "tablet" || breakpoint === "mobile" ? (
        <NavLink
          to="/profile"
          style={{
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
        >
          <MyProfileContainer onClick={handleProfileImageClick}>
            {breakpoint !== "mobile" && (
              <ProfileImage src={avatarUrl ?? "https://talenti-static.s3.amazonaws.com/avatars/default_avatar.png"} alt="profile" id="profile-button" />
            )}
            <Typography variant="body1">Мој профил</Typography>
          </MyProfileContainer>
        </NavLink>
      ) : (
        <>
          <ProfileImage
            src={avatarUrl ?? "https://talenti-static.s3.amazonaws.com/avatars/default_avatar.png"}
            alt="profile"
            onClick={handleProfileImageClick}
            id="profile-button"
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            transformOrigin={{ horizontal: 80, vertical: -20 }}
          >
            <MenuItem sx={{ paddingLeft: "10px" }} component={NavLink} to="/profile">
              Мој профил
            </MenuItem>
            <MenuItem sx={{ paddingLeft: "10px" }} component={NavLink} to="/posts/mine">
              Мои огласи
            </MenuItem>
            <MenuItem sx={{ paddingLeft: "10px" }} onClick={handleLogoutClick}>
              Одјави се
            </MenuItem>
          </Menu>
        </>
      )}
    </Container>
  );
};

export default ProfileButton;
