import styled from "styled-components/macro";

const PageContainer = styled.div<{
  py?: string;
  px?: string;
  pb?: string;
  h?: string;
  w?: string;
  paddingOverride?: string;
}>`
  display: flex;
  box-sizing: border-box;
  width: ${({ w }) => w || "100%"};
  height: ${({ h }) => h || "100%"};
  flex-direction: column;
  align-items: center;
  padding: ${({ py }) => py || "40px"} ${({ px }) => px || "10vw"};
  padding-bottom: ${({ pb }) => pb || "50px"};

  @media (max-width: 768px) {
    padding: ${({ py }) => py || "5px"} ${({ px }) => px || "10px"};
    padding-bottom: ${({ pb }) => pb || "50px"};
    ${({ paddingOverride }) => paddingOverride && `padding: ${paddingOverride}`};
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    padding: ${({ py }) => py || "20px"} ${({ px }) => px || "20px"};
    padding-bottom: 50px;
  }
`;

export default PageContainer;
