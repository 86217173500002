/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { theme } from '../../../../theme/theme';
import { useUser } from '../../../../hooks/user/useUser';


//get user


const CTA = (): JSX.Element => {
  return (
  
    <Box>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'#153B4F'}
        align={'center'}
      >
        Подготвен/а си за нови успеси?
      </Typography>
      <Typography fontWeight={700} variant={'h3'} gutterBottom align={'center'}>
      Сакаш да почнеш да го користиш Таленти.мк?
      </Typography>
      <Box marginTop={3} display={'flex'} justifyContent={'center'}>
        <Button
          component={'a'}
          href={'/login'}
          variant="contained"
          size="large"
          sx={{
            color:"white",fontWeight:"600"
          }}
          endIcon={
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          }
        >
          Креирај профил
        </Button>
      </Box>
    </Box>
  );
};

export default CTA;
