import { Typography } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

const PrivacyPolicy = () => (
  <PageContainer>
    <PolicyContainer>
      <Typography variant="h2" color="primary" gutterBottom>
        Политика за приватност
      </Typography>
      <Typography variant="body1" gutterBottom>
        ТАЛЕНТИ ДОО Скопје е посветена на заштита на приватноста и интегритетот на личните податоци
        со кои доаѓа во контакт во текот на своето работење. Во овој документ за применета политика
        за приватност (privacy policy) се назначени видовите лични податоци кои ги примаме и
        собираме при користење на веб-сајтот TALENTI.mk, како и некои од чекорите кои ги преземаме
        за нивна заштита.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Политика на приватност и заштита на личните податоци</Typography>{" "}
        Оваа Политика на приватност дава информации за обработката на лични податоци кога ја
        посетувате нашата веб страница https://talenti.mk (во понатамошниот текст TALENTI.mk или
        Портал) и/или користите некоја од нашите услуги преку истата.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Контролор на збирки на лични податоци</Typography>
        Контролор/обработувач на личните податоци е ТАЛЕНТИ ДОО Скопје со седиште на ул.
        Михаил Чаков бр. 7А-локал 006 Скопје, со ЕМБС 7675070 (во понатамошниот текст “Друштвото”).
      </Typography>
      <Typography variant="body1" gutterBottom>
        Личните податоци ги обработуваме во согласност со одредбите од важечкиот Закон за заштита на
        лични податоци и подзаконските акти.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Чии податоци се собираат?</Typography> Можеме да собираме податоци
        поврзани со следниве категории на лица:
        <br /> - На посетители на TALENTI.mk. Порталот употребува колачиња (cookies), а повеќе
        информации можете да најдете во нашата{" "}
        <Link to="/cookies-policy">Политика за користење на колачиња</Link>;
        <br /> - На Физички лица и правни лица кои креирале корисничка сметка и се регистрирале на
        Talenti.mk со цел да ги користат услугите на Порталот и да објавуваат огласи;,
        <br /> - На Компании и физички лица кои креирале корисничка сметка и се регистрирале на
        Talenti.mk со цел да ги користат услугите на Порталот и да пребаруваат адекватни кадри за
        потребите на својата компанија; Како ги собираме личните податоци ? Личните податоци ги
        собираме преку различни начини, вклучувајќи:
        <br /> - Податоци кои Корисникот ги внесува при регистрација на Порталот;
        <br /> - Податоци кои Корисникот ги внесува при пополнување на својот кориснички профил;
        <br /> - Податоци кои Корисникот ги открива и наведува при комуникација преку нашата служба
        за корисници, преку објавените е-mail адреси и телефонски броеви или преку пошта;
        <br /> - Податоци за Корисникот при користење на Порталот, вклучувајќи ги и оние кои
        доброволно ги остава при формите за аплицирање на огласи и пополнување анкети на Порталот;
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">За кои цели ги користиме личните податоци?</Typography>
        Вашите податоци ги обработуваме за конкретни, јасни и легитимни цели, во обем потребен за
        остварување на целите на обработката и нема да ги обработуваме Вашите лични податоци на
        начин што е спротивен со тие цели. Категории на податоци кои се обработуваат
        <br /> - Лични податоци за Физички лица/Работобаратели на Порталот, кои е неопходно да се
        внесат при регистрација и отворање на корисничка сметка, за целите на користење на услугите
        на Порталот - Talenti.mk (е-маил адреса, име, презиме, телефон). Секој Корисник може
        дополнително и самостојно по своја волја да внесува податоци во својот Профил/на talenti.mk
        кои се однесуваат на негови дополнителни податоци како слика, профили до социјални медиуми,
        пол, дата на раѓање, интереси за позиции за кои бара работа, дополнителни контакт податоци,
        неговото образование, работно искуство, познавање на јазици и други вештини за да ги користи
        за идна промена на работно место. Напоменуваме дека Друштвото нема пристап до податоците
        наведени во Корисничкиот Профил;
        <br /> - Лични податоци за Корисници кои не се регистрирани на Порталот, кои е неопходно да
        се внесат при најава на Порталот - Talenti.mk за целите на користење на услугата - Податоци
        за Компании/Работодавачи и физички лица кои е неопходно да се внесат при регистрација и
        отворање на корисничка сметка, за целите на користење на услугите на Порталот - Talenti.mk
        (е-маил адреса, име/назив, ЕМБС, телефон, лице за контакт, адреса, индустрија);
        <br /> - Податоци кои ги собираме при комуникација со Корисниците кои не контактирале за
        техничка или административна поддршка или соработка преку преку нашата служба за корисници;
        <br /> - Податоци за известувања по електронски пат (кои не се директен маркетинг);
        <br /> - Податоци за маркетинг и промоции, доколку сте дале согласност за обработка (е-пошта
        и/или контакт телефон);
        <br /> - Податоци (е-маил) внесени преку Контакт страната.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Кои податоци се евидентираат при посета</Typography> За време на
        посета на нашите интернет страни, платформата за секој корисник врши запис/лог со следни
        информации: <br />• Времето на посетата <br />• Страната која се посетува и подопциите при
        тоа <br />• Страната од која е препратена посетата и во некои случаи клучните зборови кои се
        користени при истотото препраќање <br />• Оперативниот систем кој се користи и нагодувањето
        за екранска резолуција <br />• Видот на пребарувачот и неговата верзија <br />• Јазикот кој
        се користи на пребарувачот <br />• Интернет (IP) адресата од која се пристапува <br />•
        Типот на уред од кој се пристапува Сите записи (логови) се чуваат заради проучување на
        сигурносен или кориснички проблем.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Врски со трети веб страници и сервиси</Typography> Содржините кои
        Порталот Talenti.mk ги објавува може да содржат врски (линкови) до веб страници и сервиси на
        трети лица на кои не се однесува оваа Политика за приватност. Ние не сме одговорни за
        практиките на овие трети веб страни и сервиси и Ве советуваме да се запознаете со содржината
        на нивните Политики за приватност.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Основ за обработка на личните податоци</Typography>
        Во согласност со важечкото законодавство за заштита на личните податоци, Вашите лични
        податоци може да бидат обработени ако:
        <br /> - сте ни дале согласност за обработката (член 10 став 1 алинеја 1 од Законот за
        заштита на личните податоци), која имате право да ја повлечете во било кое време; или
        <br /> - обработката е неопходна за извршување на договор (член 10 став 1 алинеја 2 од
        Законот за заштита на личните податоци) во кој Вие сте договорна страна или за да се
        преземат активности на Ваше барање пред Ваше пристапување кон договорот; или
        <br /> - обработката е неопходна за целите на наш легитимен интерес (член 10 став 1 став 6
        од Законот за заштита на личните податоци), без да се наруши вашето право на приватност и
        доверливост, а за постоењето на ваков легитимен интерес, ќе Ве известиме во врска со
        конкретната обработка;
        <br /> - обработката е потребна за исполнување на наша законска обврска (член 10 став 1
        алинеја 3 од Законот за заштита на личните податоци). Согласност за обработка на лични
        податоци
      </Typography>
      <Typography variant="body1" gutterBottom>
        Со користење на Порталот физичките лица и компаниите даваат изречна согласност на Друштвото
        за собирање и обработка на нивните лични податоци кои се дадени при регистрација или најава
        за брза апликација и се достапни во нивните Кориснички Профили и/или преку објавување на CV,
        молба или било кој друг документ. Корисниците даваат согласност за контактирање преку
        електронски пат како дел од функционалностите на системот (отворени работни позиции,
        заборавена лозинка, системски пораки, известувања од Порталот итн.) преку кои не се врши
        продажба и не се директен маркетинг. Корисниците овие опции може да ги исклучат самостојно
        или со допис до Друштвото. Огласувачите кои се физички лица и Работобарателите можат во било
        кој момент да ја повлечат дадената согласност без никакво дополнително објаснување со
        испраќање на барање на адресата на Друштвото за отповикување на согласноста и деактивирање
        и/или бришење на корисничката сметка. Преку аплицирање на оглас и користење на Порталот со
        аплицирање на објавени огласи, Работобарателите даваат изречна согласност но и можност за
        одбивање преку специјално копче за одбивање или прифаќање на барањето за поврзување на сите
        Работодавачи на чиј оглас би аплицирале, да можат да пристапат до нивните лични податоци
        наведени во Корисничкиот Профил, (кој покрај податоците од задолжителните полиња.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Согласноста за собирање и обработка на лични податоци во согласност од одредбите од Законот
        за заштита на лични податици се дава исклучиво за целите на аплицирање на огласи за
        потенцијални идни вработувањи кај истите кандидати. Употребата на податоците за било која
        друга цел (копирање, транскрипција, дистрибуција, итн.) без согласност на Друштвото и
        Работобарателите, односно Работодавачите (физичко лице) за масовна дистрибуција, промотивни
        пораки и слични работи е строго забрането. Друштвото ќе ги извести надлежните органи,
        вклучувајќи, но не и ограничувајќи се на Државното обвинителство на Република Северна
        Македонија, Министерството за внатрешни работи и Агенцијата за заштита на личните податоци.
        Друштвото не одговора за неовластено откривање или било каква незаконска обработка на
        личните податоци на Работобарателите од страна на Работодавачите и нема да биде одговорно за
        надомест на материјална и/или нематеријална штета што би можела да биде предизвикана.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Цели на обработка на личните податоци</Typography>
        Личните податоци ги собираме за конкретни и јасно утврдени цели и се обработуваат на начин
        што е во согласност со тие цели и во согласност со Законот за заштита на лични податоци.
        Личните податоци се обработуваат за давање услуги и исполнување на договорни обврски односно
        за пристап, регистрација и користење на Talenti.mk, преку објавување на огласи за
        потенцијални таленти од страна на огласувачите, и складирање биографии и други кориснички
        податоци во базата на профилот, за нивна достапност, во согласност со избраните поставки од
        страна на Работодавачите за целите на аплицирање на објавениот оглас на Порталот.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Пренесување на корисници на лични податоци</Typography>
        Не споделуваме податоци за Вас и/или за Вашата посета на нашата Порталот, на трети лица,
        освен во следните случаи:
        <br /> - во случај на ангажирање на даватели на услуги кои Друштвото ги ангажира како
        обработувачи со што ќе бидат обврзани да постапуваат по упатствата на друштвото и обезбедат
        заштита на личните податоци кои се обработуваат при обезбедувањето на тие услуги.
        <br /> - по барање на суд, полиција или други органи и тела за вршење на нивните надлежности
        и само ако постои законски основ за тоа.{" "}
      </Typography>{" "}
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Кој има пристап до Вашите податоци? </Typography> Во нашето друштво
        пристап до вашите податоци можат да добијат само оние лица на кои им се потребни тие
        информации за овозможување и давање на нашите услуги и исполнување на нашите договорни и
        законски обврски и само до оној степен што е неопходен за исполнување на целта на
        обработката.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Време на чување на собраните податоци</Typography>
        Податоците кои ги собираме се чуваат во согласност со закон и периодот потребен за
        постигнување на целта на обработката со рокови на чување на личните податоци потребни за
        исполнување на целта на обработката – овозможување и давање на нашите услуги и извршување на
        склучени договори. Пресметка на роковите на чување и задржување на личните податоци, се
        заснова на следниве критериуми:
        <br /> - наши законски или договорни обврски;
        <br /> - податоците дадени при регистрација, најава, или наведени во Корисничкиот профил,
        како и податоците дадени за маркетинг цели се чуваат до повлекување на согласноста, односно
        до спроведување на барање за бришење на податоци;
        <br /> - времето потребно за обработка на барањето или приговорот (доколку е поднесен
        приговор). <br />
        Некои од Вашите лични податоци потребно е да ги чуваме во архивите за да можеме да
        одговориме на какви било правни постапки и за време пропишано со важечките закони.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Кои се Вашите права како субјекти на лични податоци?</Typography>
        Вашите права во однос на обработката на лични податоци се:
        <br /> - право да бидете информирани за обработката на личните податоци (право да бидете
        информирани за какви цели се обработуваат ваши податоци, категориите на лични податоци кои
        се обработуваат, временскиот рок на чување, нашите легитимни интереси, корисниците на кои се
        пренесуваат...);
        <br /> - право на пристап до личните податоци (имате право да добиете потврда дали се
        обработуваат вашите лични податоци и доколку се обработуваат, да добиете пристап до личните
        податоци и информации за обработката);
        <br /> - право на исправка на личните податоци (ако најдете грешки во вашите лични податоци
        или ако тие се нецелосни или неточни, може да побарате да ги исправиме неточните или
        дополниме нецелосните лични податоци);
        <br /> - право на бришење на лични податоци / право да се биде заборавен (можете да побара
        бришење на Корисничкиот профил и личните податоци од базата на Корисници);
        <br /> - право на ограничување на обработката на личните податоци (под услови утврдени со
        закон, можете да побарате ограничување на Обработката на вашите лични податоци);
        <br />
        - право на приговор (имате право во секое време согласно важечкиот закон да поднесете
        приговор на обработката на вашите лични податоци, како и приговор на обработката на Вашите
        лични податоци за цели на директен маркетинг);
        <br /> - право на повлекување на согласноста за обработка на личните податоци во секое време
        (доколку сте дале согласност за обработка на личните податоци). <br />
        Корисникот во секое време има пристап до својот Кориснички Профил со својата лозинка и може
        да ги измени и исправи неточните или да ги дополни нецелосните лични податоци, да ги смени
        поставките за приватност, или да го деактивира Профилот. <br />
        При регистрацијата Корисникот самостојно ја поставува својата лозинка, која не смее да ја
        споделува со трети лица, и сноси целосна одговорност за тајноста на лозинката и користењето
        на Порталот по пат на податоците за пристап. <br />
        Работобарателот може да го извести Друштвото доколку се посомнева на неовластена употреба на
        своите податоци за пристап. <br />
        За дополнителни информации и/или остварување на Вашите права за заштита на лични податоци,
        можете да го контактирате нашиот офицер за заштита на лични податоци - Сања Зафировска,
        info@talenti.mk. <br />
        Субјектите на личните податоците можат да ги користат правата според оваа Политика со
        поднесување барање за остварување на соодветното право. <br />
        Барањето може да се поднесе на следниот начин:
        <br /> - електронски на следната адреса на info@talenti.mk
        <br /> - по пошта на адреса - ул. Михаил Чаков бр. 7А-локал 006, Скопје <br />
        Барањето за остварување на правата во врска со заштитата на личните податоци треба да ги
        содржи следниве податоци:
        <br /> - име и презиме на субјектот на лични податоци;
        <br /> - контакти за повратни информации - адреса, телефон, адреса на електронско сандаче;
        <br /> - барање со опис на барањето.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Право на поднесување на барање до АЗЛП</Typography>
        Ако сметате дека начинот на кој Друштвото ги обработува Вашите лични податоци ги повредува
        одредбите на Законот за заштита на личните податоци, можете да поднесете барање до
        Агенцијата за заштита на личните податоци како надзорно тело, согласно Законот за заштита на
        личните податоци.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Промена на политиката за приватност</Typography>
        Со пристапување и користењето на Talenti.mk изјавувате дека се согласувате со оваа Политика
        за приватност. Политиката за приватност подлежи на редовно преиспитување и по потреба ќе се
        менува и дополнува соодветно на настанатите промени и нашите активности за што ќе бидете
        информирани. Ве молиме да ја посетувате Политиката за приватност и да се запознаете со
        нејзините промени и дополнувања.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Безбедност на податоците</Typography>
        Друштвото ги презема сите потребни технички и организациски мерки за заштита на личните
        податоци од нивно случајно или незаконско уништување, губење, неовластено откривање или
        пристап, користејќи редовни контроли и најнови технолошки механизми и достигнувања во оваа
        област согласно важечкиот Закон за заштита на личните податоци и применливите подзаконските
        акти.
      </Typography>
    </PolicyContainer>
  </PageContainer>
);

export default PrivacyPolicy;
