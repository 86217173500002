/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import ForEmployeesImage from "./assets/employees-banner.png";
import "../../index.css";
import styled, { css, keyframes } from "styled-components";

const effect = keyframes`
    0% {
      background-position: 0 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
`;

const StyledGrid = styled(Grid)(
  () => css`
    background: linear-gradient(100deg, #108a0030, #48e1cf, #18c1db, #70e4ce, #108a0030);
    animation-name: ${effect};
    animation-direction: alternate;
    padding: 10px;
    border-radius: 2px;
    padding-y: 0;
    animation-duration: 8s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    background-size: 200% 200%;
    "@media (max-width: 1024px)": {
      padding: 2; // padding for tablets
    }
    ,
    "@media (max-width: 768px)": {
      padding: 3; // padding for mobile devices
    }
  `,
);

// const animated = css`
//   animation: ${effect} 8s ease-out infinite;
//   animation-direction: alternate;
// `;

const EmployeesBanner = (): JSX.Element => {
  const theme = useTheme();

  const LeftSide = () => (
    <Box marginTop={10} marginLeft={5}>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
          gutterBottom
          color={"primary"}
        >
          За работници
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={"h3"} color={"#153b4f"} sx={{ fontWeight: 700 }} gutterBottom>
          Рекламирај ги твоите вештини и најди работа.
        </Typography>
        <Typography color="#153b4f" variant={"h6"}>
          Пополни го твојот профил и отвори нови врати.
          <br />
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          [
            "Стани достапен за работа",
            "Стани достапен за сите компании кои имаат потреба од твоите вештини.",
          ],
          [
            "Одбери со која компанија ќе соработуваш",
            "Избери кои услови најмногу ти одговараат и поврзи се со таа компанија.",
          ],
          ["Издвој се од конкуренцијата", "Прикачи портфолио и докажи дека си најдобар."],
          [
            "Анонимно и безбедно",
            "Старата компанија не мора да знае, а новата ќе открие кога ти ќе сакаш",
          ],
        ].map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box
              sx={{ fontWeight: 700 }}
              component={ListItem}
              disableGutters
              width={"auto"}
              padding={0}
            >
              <Box
                component={ListItemAvatar}
                minWidth={"auto !important"}
                marginRight={2}
                color={"#153b4f"}
              >
                <Box
                  component={Avatar}
                  bgcolor={"#5fa8ce"}
                  width={20}
                  height={20}
                  sx={{
                    backgroundColor: "#5fa8ce !important",
                  }}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText
                sx={{ fontWeight: 700 }}
                primary={<Typography fontWeight={700}>{item[0]}</Typography>}
                color="#153B4F"
              />
            </Box>
            <Typography>{item[1]}</Typography>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4}>
        <Link href="/for-employees">
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              color: "white",
              fontWeight: "600",
            }}
            endIcon={
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Дознај повеќе
          </Button>
        </Link>
      </Box>
    </Box>
  );
  return (
    <StyledGrid
      container
      spacing={0}
      sx={{
        background: "#153B4F",
        padding: 10,
        borderRadius: 2,
        paddingX: 0,
        paddingY: 0,
        "@media (max-width: 1024px)": {
          padding: 2, // padding for tablets
        },
        "@media (max-width: 768px)": {
          padding: 3, // padding for mobile devices
        },
      }}
    >
      <Grid item spacing={0} xs={12} md={6}>
        <Box
          sx={{
            // maxWidth: 600,
            position: "relative",
            marginX: "auto",
            marginBottom: 5,
          }}
        >
          <LeftSide />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            maxWidth: 600,
            position: "relative",
            marginX: "auto",
          }}
        >
          <Box
            sx={{
              position: "relative",
              borderRadius: "2.75rem",
              marginX: "auto",
            }}
          >
            <Box>
              <Box
                position={"relative"}
                zIndex={2}
                maxWidth={1}
                height={"auto"}
                sx={{ verticalAlign: "middle" }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 600,
                    width: 600,
                    maxHeight: { xs: 200, md: 600 },
                    maxWidth: { xs: 200, md: 600 },
                  }}
                  alt="Banner for companies."
                  src={ForEmployeesImage}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export default EmployeesBanner;
