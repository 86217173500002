import PageContainer from "../../../components/common/PageContainer";
import useGetMyPosts from "../../../hooks/posts/useGetMyPosts";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components/macro";
import WorkerCard from "../../Home/components/WorkerCards/WorkerCard";
import { Post } from "../../../api/posts/types";

const MyPostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`;

const MyPosts = () => {
  const { data } = useGetMyPosts();
  const posts = data as unknown as Post[] | undefined; // TODO: Fix BE to return correct type

  return (
    <PageContainer>
      <MyPostsContainer>
        <Typography variant="h3" gutterBottom>
          Мои огласи
        </Typography>
        {posts?.length ? (
          <Grid container spacing={4}>
            {posts?.map((post) => (
              <Grid item xs={12} md={4} gap={2} key={post.id}>
                <WorkerCard post={post} listItem />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h5" gutterBottom>
            Немате објавено огласи.
          </Typography>
        )}
      </MyPostsContainer>
    </PageContainer>
  );
};

export default MyPosts;
