import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import styled from "styled-components/macro";

// import Container from 'components/Container';
import { Hero, Pricings, Step1, Step2, Step3 } from "./components";
import { Container, Grid, Typography } from "@mui/material";
import NewsletterBanner from "../Home/components/NewsletterBanner";
import CTA from "./components/CTA";
import AboutUsImage from "./assets/Talenti Illustration5.png";
// import Container from '../../components/Container';

const AboutUs = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 10,
      }}
    >
      <Grid container>
        <Grid item sm={8}>
          <Typography
            sx={{
              paddingBottom: 3,
              fontWeight: 700,
              fontSize: "2rem",
            }}
          >
            Добредојдовте на Таленти.мк!
          </Typography>
          Тука безбедно и анонимно ќе може да го истражите пазарот, да добивате понуди за работа без
          да си го загрозите моменталното работно место, како и конечно да ја побарате платата која
          ја очекувате.
          <Typography>
            За компаниите пак, нудиме прегледна и бесплатна база на работници за отворените работни
            позиции, па селекцијата на потенцијални вработени може да се направи многу побрзо,
            поефикасно и транспарентно.
          </Typography>
          <Typography>
            Нашата мисија е да го трансформираме начинот на кој се поврзуваат талентите и компаниите
            во Македонија, креирајќи иновативна и ефикасна платформа. Ги следиме светските трендови
            и да го подигнеме нивото на кое се бара и нуди работа на домашниот пазар.{" "}
          </Typography>
          <Typography>
            Работиме за да создадеме заедница каде што секој човек има можност за напредок и развој,
            каде што компаниите имаат лесен пристап до најдобрите таленти.{" "}
          </Typography>
          <Typography>
            Долгорочната визија е да го промениме начинот на кои луѓето бараат и наоѓаат работа, а
            компаниите стигнуваат до квалитетен кадар, креирајќи динамична заедница каде индивидуите
            и компаниите можат да растат заедно.{" "}
          </Typography>
          <Typography>
            Со ова, не само што се стремиме кон задоволување на моменталните потреби на пазарот,
            туку и создаваме можност преку која секој ќе си ја најде работата и платата со која е
            задоволен.{" "}
          </Typography>
          <Typography>
            Свесни сме дека денешниот пазар на труд е динамичен и брз, па затоа се трудиме да
            создадеме платформа која ќе ви помогне да го следите темпото. Таленти.мк е повеќе од
            платформа за вработување – се стремиме да станеме заедница каде се сретнуваат талентот и
            можностите.
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Box
            component="img"
            sx={{
              height: 500,
              width: 500,
              maxHeight: { xs: 200, md: 600 },
              maxWidth: { xs: 200, md: 600 },
            }}
            alt="Banner for companies."
            src={AboutUsImage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
