/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import ForCompaniesImage from "./assets/for-companies.png";

const CompanyBanner = (): JSX.Element => {
  const theme = useTheme();

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
          gutterBottom
          color={"white"}
        >
          За работодавци
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={"h3"} sx={{ fontWeight: 700 }} gutterBottom>
          Најди работници за твојот бизнис, брзо и едноставно.
        </Typography>
        <Typography variant={"h6"}>
          Бесплатна база на работници од цела Македонија.
          <br />
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          "Бесплатна база на работници",
          "Брзо и лесно пребарување",
          "Избегнете прибирање CV-ја",
          "Пронајдете го идеалниот кандидат",
        ].map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box component={ListItem} disableGutters width={"auto"} padding={0}>
              <Box component={ListItemAvatar} minWidth={"auto !important"} marginRight={2}>
                <Box
                  component={Avatar}
                  width={30}
                  height={30}
                  sx={{
                    backgroundColor: "red",
                  }}
                >
                  <svg
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    // fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4}>
        <Link href="/posts">
          <Button
            variant={"contained"}
            size={"large"}
            sx={{
              color: "#153B4F",
              fontWeight: 600,
              backgroundColor: "white",
            }}
            endIcon={
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Пребарувај
          </Button>
        </Link>
      </Box>
    </Box>
  );
  return (
    <Grid
      container
      spacing={0}
      sx={{
        background: "#153B4F",
        padding: 10,
        borderRadius: 2,
        color: "#fff",
        paddingX: 0,
        paddingY: 0,
        "@media (max-width: 1024px)": {
          padding: 2, // padding for tablets
        },
        "@media (max-width: 768px)": {
          padding: 3, // padding for mobile devices
        },
      }}
    >
      <Grid item xs={12} md={6} spacing={0}>
        <Box
          sx={{
            // maxWidth: 600,
            position: "relative",
            marginX: "auto",
          }}
        >
          <Box
            sx={{
              position: "relative",
              borderRadius: "2.75rem",
              // width: '75% !important',
              marginX: "auto",
            }}
          >
            <Box>
              <Box
                position={"relative"}
                zIndex={2}
                maxWidth={1}
                height={"auto"}
                sx={{ verticalAlign: "middle" }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 600,
                    width: 600,
                    maxHeight: { xs: 200, md: 600 },
                    maxWidth: { xs: 200, md: 600 },
                  }}
                  alt="Banner for companies."
                  src={ForCompaniesImage}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems={"center"} xs={12} md={6}>
        <Box>
          <RightSide />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompanyBanner;
