import { forwardRef } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Checkbox } from "@mui/material";

const VisibilityCheckbox = forwardRef<HTMLInputElement, { [x: string]: unknown }>((props, ref) => (
  <Checkbox icon={<Visibility />} checkedIcon={<VisibilityOff />} {...props} inputRef={ref} />
));

VisibilityCheckbox.displayName = "VisibilityCheckbox";

export default VisibilityCheckbox;
