import { useMutation } from "react-query";
import { subscribeToNewsletter } from "../../api/utilities";
import { toast } from "react-toastify";

export const useSubscribeToNewsletter = () =>
  useMutation(subscribeToNewsletter, {
    onSuccess: () => {
      toast("Успешно се претплативте! Ви благодариме!", {
        type: "success",
        position: "bottom-center",
      });
    },
    onError: () => {
      toast("Настана грешка. Ве молиме обидете се повторно.", {
        type: "error",
        position: "bottom-center",
      });
    },
  });
