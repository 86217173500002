import { Navigate, useLocation, useParams } from "react-router";
import { useSocialLogin } from "../hooks/auth/useSocialLogin";
import { SocialLoginPayload } from "../api/auth/types";

const isValidProvider = (provider: string): provider is "google" | "facebook" =>
  provider === "google" || provider === "facebook";

const getPayload = (
  provider: string,
  code: string,
  searchParams: URLSearchParams,
): SocialLoginPayload | null => {
  if (!isValidProvider(provider)) {
    return null;
  }
  return provider === "facebook"
    ? {
        provider,
        code,
      }
    : {
        provider,
        code,
        scope: searchParams.get("scope") || "",
        authuser: searchParams.get("authuser") || "",
        prompt: searchParams.get("prompt") || "",
      };
};
const SocialLoginHandler = () => {
  const { provider } = useParams();

  const socialLogin = useSocialLogin();
  if (provider !== "google" && provider !== "facebook") {
    return <Navigate to="/login" />;
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  if (!code) {
    return <Navigate to="/login" />;
  }

  const params = getPayload(provider, code, searchParams);
  if (!params) return <Navigate to="/login" />;

  socialLogin.mutate(params);

  return <Navigate to="/login" />;
};

export default SocialLoginHandler;
