import WorkerCard from "./WorkerCard";
import { Post } from "../../../../api/posts/types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid } from "@mui/material";

type WorkerCardsProps = {
  posts: Post[];
  variant?: "grid" | "scrollable-list" | "default";
  isCarousel?: boolean;
};

//settings, if mobile 1 slide to show

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024, // breakpoint for larger tablets
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // breakpoint from where the settings should apply
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const WorkerCards = ({ posts, variant = "grid", isCarousel = false }: WorkerCardsProps) => (
  <Box>
    {
      // isCarousel ? (
      //   <Slider {...settings}>
      //     {posts.map((item) => (
      //       <WorkerCard key={item.id} post={item} />
      //     ))}
      //   </Slider>
      // ) : (
      <Grid container spacing={1} my={1}>
        {posts.map((item) => (
          <Grid
            key={item.id}
            item
            md={4}
            sx={{
              width: "100%",
            }}
            display="flex"
          >
            <WorkerCard post={item} />
          </Grid>
        ))}
      </Grid>

      // )
    }
  </Box>
);

export default WorkerCards;
