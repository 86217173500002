import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFaq from "../../../../hooks/useFaq";
import { GetFaqResponse } from "../../../../api/faq/types";

interface FaqGroupItemProps {
  title: string;
  items: Array<{
    title: string;
    subtitle: string;
  }>;
}

const FaqGroupItem = ({ title, items }: FaqGroupItemProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box>
      {/* <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box> */}
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              "&::before": {
                display: "none",
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Content = (): JSX.Element => {
  const { data: faq, isLoading, isError } = useFaq();

  // Wait until data is available
  if (isLoading || !faq) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (isError) {
    return <div>Error fetching FAQ data. Please try again later.</div>;
  }
  const data = (faq as unknown as GetFaqResponse)
    ?.map((item) => ({
      title: item.question,
      subtitle: item.answer,
      sortOrder: item.sortOrder,
      categorySlug: item.category_slug,
      categoryName: item.category_name,
    }))
    .sort((a, b) => b?.sortOrder - a?.sortOrder);

  const categories = data?.reduce((acc: { slug: string; name: string }[], curr) => {
    if (!acc.some((item) => item.slug === curr.categorySlug)) {
      acc.push({ slug: curr.categorySlug, name: curr.categoryName });
    }
    return acc;
  }, []);

  return (
    <Box>
      {categories?.map((category, i) => (
        <Box key={i} marginBottom={6}>
          <FaqGroupItem
            key={i}
            title={category.name}
            items={data?.filter((item) => item.categorySlug === category.slug)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
