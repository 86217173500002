import { useMutation } from "react-query";
import { sendFriendRequest } from "../../api/connections";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const useSendFriendRequest = () =>
  useMutation(sendFriendRequest, {
    onSuccess: () => {
      toast.success("Успешно испратена покана за поврзување!", { position: "bottom-center" });
    },
    onError: (e: unknown) => {
      const message = ((e as AxiosError).response?.data as { message: string })?.message?.includes(
        "already",
      )
        ? "Веќе постои покана за поврзување."
        : "Настана грешка. Ве молиме обидете се повторно.";
      toast.error(message, { position: "bottom-center" });
    },
  });

export default useSendFriendRequest;
