import { useMutation } from "react-query";
import { contact } from "../../api/utilities";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export const useContact = () => {
  const navigate = useNavigate();

  return useMutation(contact, {
    onSuccess: () => {
      toast("Вашата порака е успешно испратена!", {
        type: "success",
        position: "bottom-center",
      });
      navigate("/");
    },
    onError: () => {
      toast("Настана грешка при испраќањето на пораката. Ве молиме обидете се повторно.", {
        type: "error",
        position: "bottom-center",
      });
    },
  });
};
