import { createContext } from "react";
import { User } from "../api/users/types";

type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {
    throw new Error("setUser function not implemented");
  },
});
