import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Content, Footer, Headline } from './components';
import ContainedBox from '../../components/common/ContainedBox';

const Faq = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Box
          sx={{
            // backgroundColor: theme.palette.primary.dark,
            // backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.dark} 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <ContainedBox>
            <Headline />
          </ContainedBox>
        </Box>
        <ContainedBox maxWidth={800}>
          <Content />
        </ContainedBox>
        <Box>
          <ContainedBox>
            <Footer />
          </ContainedBox>
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;
