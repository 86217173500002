import { Autocomplete, CircularProgress, TextField, TextFieldProps } from "@mui/material";
import { Category } from "../../../api/utilities/types";
import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import useSearchCategories from "../../../hooks/categories/useSearchCategories";
import { UTILS_ROUTES } from "../../../api/utilities/routes";

export interface CategoryByName {
  id: number;
  name: string;
}

interface CategoriesAutocompleteProps {
  includeAll?: boolean;
  defaultValue?: CategoryByName | null;
  onChoose?: (categoryId: number) => void;
  categoryId: number;
  options?: CategoryByName[];
  onGetOptions?: (options: CategoryByName[]) => void;
  fields?: string;
  inputProps?: TextFieldProps;
}

export default function CategoriesAutocomplete({
  includeAll = true,
  onChoose,
  categoryId,
  defaultValue,
  options: _options,
  onGetOptions,
  fields,
  inputProps = {},
}: CategoriesAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CategoryByName[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryByName | null>(null);

  const handleCategoryChange = (event: any, value: CategoryByName | null) => {
    setSelectedCategory(value);
    onChoose?.(value?.id ?? 0);
  };

  const loading = false;

  useEffect(() => {
    if (onGetOptions) {
      setOptions(_options ?? []);
    }
    setSelectedCategory(defaultValue ?? null);
  }, [_options]);

  const ref = useRef(null);

  const getCategories = async (s: string) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + UTILS_ROUTES.SEARCH_CATEGORIES + `?s=${s}`,
    );
    const data = await response.json();
    if (response.status === 200) {
      if (onGetOptions) {
        onGetOptions?.(data);
      } else {
        setOptions(data);
      }
    }
  };

  useEffect(() => {
    getCategories("").then(() => {
      if (onGetOptions) {
        setOptions(_options ?? []);
      }
    });
  }, [_options, onGetOptions]);


  return (
    <Autocomplete
      id="category-select"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={[
        ...(includeAll
          ? [
              {
                id: 0,
                name: "Сите",
                description: "",
                image: "",
                parent_id: 0,
                slug: "",
                total_searches: 0,
                parent_name: "",
              },
            ]
          : []),
        ...options,
      ]}
      loading={loading}
      filterOptions={(x) => x}
      onChange={handleCategoryChange}
      onInputChange={(event, newInputValue) => {
        getCategories(newInputValue);
      }}
      value={selectedCategory ?? null}
      // value={defaultValue ?? null}
      defaultValue={(defaultValue as CategoryByName) ?? null}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Професија"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          {...inputProps}
        />
      )}
    />
  );
}
