import styled from "styled-components/macro";
import { Friendship, ReceivedFriendship, SentFriendship } from "../../../api/connections/types";
import { Typography } from "@mui/material";
import Button from "../../../components/Button";

const FriendshipItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
`;

const FriendshipItemAvatarContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FriendshipItemAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  object-fit: cover;
`;

const FriendshipInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px 10px;
`;

const FriendshipActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

type FriendshipListItemProps =
  | {
    friendship: ReceivedFriendship;
    type: "incoming";
    onAccept: (friendship: Friendship) => void;
    onDecline: (friendship: Friendship) => void;
  }
  | {
    friendship: SentFriendship;
    type: "outgoing";
    onAccept?: never;
    onDecline?: never;
  };

const FriendshipListItem = ({ friendship, onAccept, onDecline, type }: FriendshipListItemProps) => (
  <FriendshipItemContainer>
    <FriendshipItemAvatarContainer>
      <FriendshipItemAvatar
        src={
          (type === "outgoing"
            ? friendship.receiver?.profile?.avatar
            : friendship.sender?.profile?.avatar) ?? "https://talenti-static.s3.amazonaws.com/avatars/default_avatar.png"
        }
        alt="avatar"
      />
    </FriendshipItemAvatarContainer>
    <FriendshipInfoContainer>
      {type === "outgoing" ? (
        <>
          <Typography variant="body1" color="text.secondary">
            {friendship.receiver?.profile?.first_name && friendship.receiver?.profile?.last_name
              ? `${friendship.receiver?.profile?.first_name} ${friendship.receiver?.profile?.last_name}`
              : "Анонимен корисник"}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {friendship.receiver?.email}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" color="text.secondary">
            {friendship.sender?.profile?.first_name && friendship.sender?.profile?.last_name
              ? `${friendship.sender?.profile?.first_name} ${friendship.sender?.profile?.last_name}`
              : "Анонимен корисник"}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {friendship.sender?.email}
          </Typography>
        </>
      )}
    </FriendshipInfoContainer>
    {type === "incoming" && (
      <FriendshipActionsContainer>
        <Button variant="contained" color="primary" onClick={() => onAccept(friendship)}>
          Прифати
        </Button>
        <Button variant="contained" color="error" onClick={() => onDecline(friendship)}>
          Одбиј
        </Button>
      </FriendshipActionsContainer>
    )}
  </FriendshipItemContainer>
);

export default FriendshipListItem;
