import { httpGet, httpPost } from "../http";
import { PaginationPayload } from "../types";
import { UTILS_ROUTES } from "./routes";
import {
  ContactPayload,
  GetCategoriesResponse,
  GetEmploymentStatusTypesResponse,
  GetRegionsResponse,
  SearchCategoriesPayload,
  SubscribeToNewsletterPayload,
} from "./types";

export const getAllCategories = async (pagination: PaginationPayload = {}) =>
  await httpGet<GetCategoriesResponse>(UTILS_ROUTES.GET_ALL_CATEGORIES, pagination);

export const getPopularCategories = async (pagination: PaginationPayload = {}) =>
  await httpGet<GetCategoriesResponse>(UTILS_ROUTES.GET_POPULAR_CATEGORIES, pagination);

export const getAllRegions = async (pagination: PaginationPayload = {}) =>
  await httpGet<GetRegionsResponse>(UTILS_ROUTES.GET_ALL_REGIONS, pagination);

export const getEmploymentStatusTypes = async () =>
  await httpGet<GetEmploymentStatusTypesResponse>(UTILS_ROUTES.GET_EMPLOYMENT_STATUS_TYPES);

export const contact = async (payload: ContactPayload) =>
  await httpPost<ContactPayload, unknown>(UTILS_ROUTES.CONTACT, payload);

export const subscribeToNewsletter = async (payload: SubscribeToNewsletterPayload) =>
  await httpPost<SubscribeToNewsletterPayload, unknown>(UTILS_ROUTES.NEWSLETTER, payload);

export const searchCategories = async (query: SearchCategoriesPayload = {}) =>
  await httpGet<GetCategoriesResponse>(UTILS_ROUTES.SEARCH_CATEGORIES, query);