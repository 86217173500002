import { Grid, TextField } from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";
import Button from "../../../../components/Button";

const ServicesForm = () => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });

  return (
    <>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid item container spacing={2} key={field.id} pb={index === fields.length - 1 ? 0 : 4}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Име на услуга"
                {...register(`services.${index}.name`, { required: true })}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Опис на услуга"
                {...register(`services.${index}.description`, { required: false })}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Цена"
                type="number"
                {...register(`services.${index}.price`, { required: true })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="button" variant="contained" color="error" onClick={() => remove(index)}>
                Избриши услуга
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => append({ name: "", price: 0 })}
            sx={{ minWidth: 300 }}
          >
            Дополнителни услуги
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ServicesForm;
