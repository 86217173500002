import styled from "styled-components/macro";
import { Category, Region } from "../../../api/utilities/types";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import useBreakpoints from "../../../hooks/utils/useBreakpoints";
import useSearchCategories from "../../../hooks/categories/useSearchCategories";
import CategoriesAutocomplete from "./CategoriesAutocomplete";

const DropdownSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 767px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px 0;
    gap: 5px;
  }
`;

const HorizontalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const StyledFormControl = styled(FormControl)`
  width: 30%;
  height: 100%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

type DropdownSearchProps = {
  onRegionChange: (region: string) => void;
  onCategoryChange: (category: string) => void;
  onSearchTextChange: (searchText: string) => void;
  selectedRegionId?: string;
  selectedCategoryId?: string;
  searchText: string;
  regions: Region[];
  // categories: Category[];
};

const DropdownSearch = ({
  onRegionChange,
  onCategoryChange,
  onSearchTextChange,
  selectedRegionId,
  selectedCategoryId,
  searchText,
  regions,
}: // categories,
DropdownSearchProps) => {
  const { breakpoint } = useBreakpoints();

  const handleRegionChange = (_: unknown, value: Region | null) =>
    onRegionChange(`${value?.id || ""}`);

  const handleCategoryChange = (categoryId: number | null) =>
    onCategoryChange(`${categoryId || ""}`);

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTextChange(event.target.value);
  };

  const { data: categoriesData, isLoading } = useSearchCategories({ s: searchText });
  const categories = categoriesData ?? [];

  return (
    <DropdownSearchContainer>
      <StyledFormControl>
        <TextField
          id="search-text"
          value={searchText}
          onChange={handleSearchTextChange}
          placeholder="Пребарај"
        />
      </StyledFormControl>

      {breakpoint !== "mobile" && (
        <>
          <HorizontalDivider />

          <StyledFormControl>
            <Autocomplete
              id="region-select"
              options={[
                {
                  id: 0,
                  name: "Сите",
                  description: "",
                  image: "",
                  parent_id: 0,
                },
                ...regions,
              ]}
              getOptionLabel={(option) => option.name}
              value={regions.find((region) => `${region.id}` === selectedRegionId) ?? null}
              onChange={handleRegionChange}
              renderInput={(params) => <TextField {...params} label="Град" />}
            />
          </StyledFormControl>

          <HorizontalDivider />

          <StyledFormControl>
            {/* <Autocomplete
              id="category-select"
              options={[
                {
                  id: 0,
                  name: "Сите",
                  description: "",
                  image: "",
                  parent_id: 0,
                  slug: "",
                  total_searches: 0,
                  parent_name: "",
                },
                ...categories,
              ]}
              getOptionLabel={(option) => option.name}
              value={categories.find((category) => `${category.id}` === selectedCategoryId) ?? null}
              onChange={handleCategoryChange}
              groupBy={(option) => option.parent_name}
              renderInput={(params) => <TextField {...params} label="Професија" />}
            /> */}
            {/* <Autocomplete
      id="category-select"
      options={categories}
      getOptionLabel={(option) => option.name}
      value={categories.find((category) => `${category.id}` === selectedCategoryId) ?? null}
      onChange={handleCategoryChange}
      groupBy={(option) => option.parent_name}
      renderInput={(params) => <TextField {...params} label="Професија" />}
      loading={isLoading}
    /> */}
            <CategoriesAutocomplete categoryId={0} onChoose={handleCategoryChange} />
          </StyledFormControl>
        </>
      )}
    </DropdownSearchContainer>
  );
};

export default DropdownSearch;
