/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Network from './assets/network.png';

const Step2 = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box marginBottom={10} sx={{
      padding: { xs: 5, md: 10 },
      borderRadius: 2
    }}>
      <Grid container spacing={4}>

        <Grid item xs={12} md={6}>
          <Box height={1} width={1} display={'flex'} flexDirection={'column'}>

            <Box
              component={'img'}
              width={{ xs: 250, md: 500 }}
              height={{ xs: 250, md: 500 }}
              src={Network}
              alt="..."
              marginLeft={'calc(100% - 500 px)'}
              marginTop={'20%'}
              zIndex={1}
              data-aos={'fade-up'}
              sx={{
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.5)' : 'none',
              }}
            />
          </Box>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography variant={'h2'} sx={{ fontWeight: 700 }} gutterBottom>
                Вашиот нов регрутер!
              </Typography>
              <Typography color="text.secondary">
                Таленти.мк може да биде и твојот нов регрутер и конечно да заврши времето на прибирање десетици, ако не и стотици CV апликации.
                <br /><br />
                Нудиме детална, а сепак комплетно бесплатна база од физички лица за секоја работна позиција. Со помош на нивното внесено портфолио, селекцијата ќе биде многу полесна и побрза.
                <br /><br />
                Компаниите најдобро ја знаат моќта на вмрежувањето, а токму тоа и го нудиме.
                <br /><br />
                Кога ќе се регристрирате како компанија, веднаш добивате пристап до комплетно бесплатна листа на работници, независно дали барате full-time или part-time вработен.
                <br /><br />
                Бесплатната листа на достапни работници во моментот ќе ви ја олесни потрагата по нов вработен, без да користите регрутери. Таленти.мк тука се наоѓа во улога на супер корисен онлајн регрутер.

              </Typography>

            </Box>

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step2;
