import { CircularProgress, Divider, Typography } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import useGetConnections from "../../hooks/connections/useGetConnections";
import { FriendshipsContainer, FriendshipsList } from "./styled";
import { GetConnectionsResponse } from "../../api/connections/types";
import FriendshipListItem from "./components/FriendshipListItem";
import useDeclineRequest from "../../hooks/connections/useDeclineRequest";
import useAcceptRequest from "../../hooks/connections/useAcceptRequest";
import useGetAcceptedRequests from "../../hooks/connections/useGetAcceptedRequests";
import { useUser } from "../../hooks/user/useUser";

const FriendRequests = () => {
  const { user } = useUser();

  const { data, isLoading } = useGetConnections();
  const requests = data as unknown as GetConnectionsResponse; // TODO: fix BE response

  const { data: { data: acceptedConnectionsData } = {} } = useGetAcceptedRequests();
  const acceptedConnections = acceptedConnectionsData?.filter((c) => c.status === "accepted") ?? [];

  const declineFriendRequest = useDeclineRequest();
  const acceptFriendRequest = useAcceptRequest();

  const hasSentRequests = !!requests?.sent?.length;
  const hasReceivedRequests = !!requests?.received?.length;
  const hasAcceptedRequests = !!requests?.friends?.length;

  if (isLoading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <FriendshipsContainer>
        <>
          {hasReceivedRequests && (
            <>
              <Typography variant="h4" color="text.secondary">
                Добиени покани за поврзување
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Доколку прифатите покана за поврзување, корисникот ќе има пристап до анонимните
                податоци на Вашите огласи.
              </Typography>
              <FriendshipsList>
                {requests?.received?.map((friendship, index) => (
                  <>
                    <FriendshipListItem
                      key={friendship.id}
                      friendship={friendship}
                      type="incoming"
                      onAccept={() =>
                        acceptFriendRequest.mutate({ connectionId: `${friendship.id}` })
                      }
                      onDecline={() =>
                        declineFriendRequest.mutate({ connectionId: `${friendship.id}` })
                      }
                    />
                    {index !== requests.received.length - 1 && <Divider />}
                  </>
                ))}
              </FriendshipsList>
            </>
          )}
          {hasSentRequests && (
            <>
              <Typography variant="h4" color="text.secondary">
                Испратени покани за поврзување
              </Typography>
              <FriendshipsList>
                {requests?.sent?.map((friendship, index) => (
                  <>
                    <FriendshipListItem
                      key={friendship.id}
                      friendship={friendship}
                      type="outgoing"
                    />
                    {index !== requests.sent.length - 1 && <Divider />}
                  </>
                ))}
              </FriendshipsList>
            </>
          )}
          {hasAcceptedRequests && (
            <>
              <Typography variant="h4" color="text.secondary">
                Конекции
              </Typography>
              <FriendshipsList>
                {requests?.friends
                  ?.map((c) =>
                    c.receiver_id === user?.id
                      ? {
                          ...c,
                          receiver: c.sender,
                          receiver_id: c.sender_id,
                          sender: c.receiver,
                          sender_id: c.receiver_id,
                        }
                      : c,
                  )

                  ?.map((friendship, index) => (
                    <>
                      <FriendshipListItem
                        key={friendship.id}
                        friendship={friendship}
                        type="outgoing"
                      />
                      {index !== acceptedConnections.length - 1 && <Divider />}
                    </>
                  ))}
              </FriendshipsList>
            </>
          )}
        </>
      </FriendshipsContainer>
    </PageContainer>
  );
};

export default FriendRequests;
