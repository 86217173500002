import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import { User } from "../../api/users/types";
import { EmploymentStatusType, Region } from "../../api/utilities/types";
import PageContainer from "../../components/common/PageContainer";
import background from "../../components/common/assets/background.png";
import { useUpdateProfile } from "../../hooks/user/useUpdateProfile";
import AvatarUpload from "./components/AvatarUpload";
import Button from "../../components/Button";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 50px 20px;
  gap: 16px;
  background-image: url(${background});
  background-size: cover;

  @media (min-width: 768px) and (max-width: 1080px) {
    width: 75vw;
    box-sizing: border-box;
  }
`;

const FormHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 50px 0px;
  box-sizing: border-box;
`;

export type ProfileFormInputs = {
  first_name: string;
  last_name: string;
  birthday: dayjs.Dayjs | undefined;
  gender: "male" | "female";
  address: string;
  city: string;
  avatar: string;
  phone?: string;
  user_type: "user" | "company";
};

type ProfileFormProps = {
  user: User;
  regions: Region[];
};

const ProfileForm = ({ user, regions }: ProfileFormProps) => {
  const navigate = useNavigate();

  const updateUserProfile = useUpdateProfile();

  const [hasCustomAvatar, setHasCustomAvatar] = useState(!!user?.profile?.avatar);

  const initialValues: ProfileFormInputs = {
    first_name: user?.profile?.first_name ?? "",
    last_name: user?.profile?.last_name ?? "",
    birthday: user?.profile?.birthday ? dayjs(user?.profile?.birthday) : undefined,
    gender: user?.profile?.gender ?? "male",
    address: user?.profile?.address ?? "",
    city: user?.profile?.city ?? "",
    avatar: user?.profile?.avatar ?? "",
    phone: user?.phone ?? "",
    user_type: user?.user_type ?? "user",
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProfileFormInputs>({
    mode: "onSubmit",
    defaultValues: initialValues,
  });

  const values = watch();

  useEffect(() => {
    (async () => {
      if (!hasCustomAvatar) {
        const image = values.gender === "male" ? "https://talenti-static.s3.amazonaws.com/avatars/default_avatar_male.png" : "https://talenti-static.s3.amazonaws.com/avatars/default_avatar_female.png";
  
        setValue("avatar", image);
      }
    })();
  }, [values.avatar, values.gender, hasCustomAvatar]);

  const completionPercentage = () => {
    const vals =
      values.user_type === "company"
        ? Object.entries(values)
            .filter(([key]) => key !== "last_name" && key !== "gender" && key !== "birthday")
            .map(([k, v]) => v)
        : Object.values(values);

    const initialVals =
      values.user_type === "company"
        ? Object.entries(initialValues)
            .filter(([key]) => key !== "last_name" && key !== "gender" && key !== "birthday")
            .map(([k, v]) => v)
        : Object.values(initialValues);

    return Math.floor((vals.filter(Boolean).length / initialVals.length) * 100);
  };

  const onSubmit = (inputs: ProfileFormInputs) => {
    if (!inputs.avatar) {
      toast.error("Ве молиме одберете слика.", { position: "bottom-center" });
      return;
    }

    const data = {
      ...inputs,
      birthday: inputs?.birthday ? inputs.birthday.toISOString() : "",
    };

    updateUserProfile.mutate(data, { onSuccess: () => navigate("/") });
  };

  return (
    <PageContainer py="0">
      <ProfileContainer>
        <ProfileHeader>
          <Box display="flex" gap="8px">
            <Typography variant="h4">Профилот е </Typography>
            <Typography variant="h4" color="primary">
              {completionPercentage()}% комплетен.
            </Typography>
          </Box>
          <AvatarUpload
            avatarBase64={values.avatar ?? ""}
            setAvatarBase64={(avatarBase64: string) => {
              setValue("avatar", avatarBase64);
              setHasCustomAvatar(!!avatarBase64);
            }}
            hasCustomAvatar={hasCustomAvatar}
          />
        </ProfileHeader>
        <FormHeaderContainer>
          <Typography variant="h3" color="secondary">
            Лични податоци
          </Typography>
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Email адреса" value={user.email} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Телефонски број"
                {...register("phone")}
                error={!!errors.phone}
                helperText={errors.phone && "Телефонскиот број не е валиден"}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel variant="outlined">Тип на корисник</InputLabel>
                <Select
                  label="Тип на корисник"
                  defaultValue={values.user_type}
                  {...register("user_type", { required: true })}
                  value={watch("user_type")}
                  error={!!errors.user_type}
                >
                  <MenuItem value="user">Физичко лице</MenuItem>
                  <MenuItem value="company">Правно лице</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.user_type && "Типот на корисник е задолжителен"}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={values.user_type === "user" ? 6 : 12}>
              <TextField
                fullWidth
                label={`Име${values.user_type === "user" ? "" : " на компанија"}`}
                {...register("first_name", { required: true })}
                error={!!errors.first_name}
                helperText={errors.first_name && "Името е задолжително"}
              />
            </Grid>
            {values.user_type === "user" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Презиме"
                  {...register("last_name", { required: true })}
                  error={!!errors.last_name}
                  helperText={errors.last_name && "Презимето е задолжително"}
                />
              </Grid>
            )}
            {values.user_type === "user" && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel variant="outlined">Пол</InputLabel>
                    <Select
                      label="Пол"
                      defaultValue={values.gender}
                      {...register("gender", { required: true })}
                      value={watch("gender")}
                      error={!!errors.gender}
                    >
                      <MenuItem value="male">Машки</MenuItem>
                      <MenuItem value="female">Женски</MenuItem>
                    </Select>
                    <FormHelperText>{errors.gender && "Полот е задолжителен"}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      sx={{
                        width: "100%",
                      }}
                      format="DD/MM/YYYY"
                      label="Датум на раѓање"
                      defaultValue={values.birthday}
                      {...register("birthday", { required: true })}
                      onChange={(date) => setValue("birthday", dayjs(date))}
                      disableFuture
                      maxDate={dayjs().subtract(13, "year")}
                    />
                    <FormHelperText>
                      {errors.birthday && "Датумот на раѓање е задолжителен"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Адреса"
                {...register("address", { required: true })}
                error={!!errors.address}
                helperText={errors.address && "Адресата е задолжителна"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel variant="outlined">Град</InputLabel>
                <Select
                  defaultValue={values.city}
                  label="Град"
                  {...register("city", { required: true })}
                  value={watch("city")}
                  error={!!errors.city}
                >
                  {regions.map((region) => (
                    <MenuItem key={region.id} value={region.name}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.city && "Градот е задолжителен"}</FormHelperText>
              </FormControl>
            </Grid>
           
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="secondary">
                  Овие податоци може да ги ажурирате во било кое време.
                </Typography>
                <Typography variant="caption" color="secondary">
                  Дел од овие податоци ќе бидат спремни за користење во огласите кои ги објавувате.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={updateUserProfile.isLoading}
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
              >
                Зачувај
              </Button>
            </Grid>
          </Grid>
        </form>
      </ProfileContainer>
    </PageContainer>
  );
};

export default ProfileForm;
