/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Blog } from "../../../../api/blog/types";
import dayjs from "dayjs";

const Content = (post: Blog): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 2, md: 6 }}>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <Box width={1} height={1} marginY={4}>
          <img
            height={"100%"}
            width={"100%"}
            src={post.image}
            alt="Remote working"
            loading="lazy"
            style={{
              filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
              objectFit: "cover",
              borderRadius: 8,
              width: "100%",
              height: "100%",
              maxHeight: 400,
            }}
          />
        </Box>
      </Box>
      {/* 
      <Box
        component={Card}
        boxShadow={2}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row-reverse" }}
        sx={{ backgroundImage: "none" }}
      >
        <Box
          sx={{
            width: { xs: 1, md: "50%" },
            position: "relative",
          }}
        >
          <Box
            component={"img"}
            loading="lazy"
            height={1}
            width={1}
            src={"https://assets.maccarianagency.com/backgrounds/img1.jpg"}
            alt="..."
            sx={{
              objectFit: "cover",
              maxHeight: 360,
              filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
            }}
          />
          <Box
            component={"svg"}
            viewBox="0 0 112 690"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              position: "absolute",
              bottom: 0,
              top: "-50%",
              left: 0,
              right: 0,
              color: theme.palette.background.paper,
              transform: "scale(2)",
              height: 1,
              width: "auto",
              transformOrigin: "top center",
              display: { xs: "none", md: "block" },
            }}
          >
            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z" fill="currentColor" />
          </Box>
        </Box>
        <CardContent
          sx={{
            position: "relative",
            width: { xs: 1, md: "50%" },
            display: "flex",
            alignItems: "center",
          }}
        >
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"h6"} gutterBottom>
                  Download our sturtup giude
                </Typography>
                <Typography color={"text.secondary"}>
                  Small heading for a smaller transition
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Name *" variant="outlined" name={"name"} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email *" variant="outlined" name={"email"} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Button
                  size={"large"}
                  fullWidth
                  variant={"contained"}
                  type={"submit"}
                  sx={{ height: 54 }}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Box> */}

      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Box display={"flex"} alignItems={"center"}>
          {/* <Avatar
            sx={{ width: 50, height: 50, marginRight: 2 }}
            src={"https://assets.maccarianagency.com/avatars/img3.jpg"}
          /> */}
          <Box>
            <Typography fontWeight={600}>{post.author}</Typography>
            <Typography color={"text.secondary"}>
              {dayjs(post.created_at).format("DD.MM.YYYY")}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography color={"text.secondary"}>Сподели:</Typography>
          <Box marginLeft={0.5}>
            <IconButton aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
