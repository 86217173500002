import { Typography } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import { useForgotPassword } from "../../hooks/auth/usePasswordUtils";
import { LoginContainer, LoginForm, LoginTextField } from "../Login/styled";
import { useForm } from "react-hook-form";
import { ForgotPasswordPayload } from "../../api/auth/types";
import Button from "../../components/Button";
import { getEmailError } from "../Login/validation";

const ForgotPassword = () => {
  const forgotPassword = useForgotPassword();

  const onSubmit = (data: ForgotPasswordPayload) => forgotPassword.mutate(data);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordPayload>({
    defaultValues: {
      email: "",
    },
  });
  return (
    <PageContainer py="0">
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4">Заборавена лозинка</Typography>
          <Typography variant="body1">
            Внесете ја вашата email адреса за да ја ресетирате вашата лозинка.
          </Typography>
          <LoginTextField
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            label="Email адреса"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.email}
            helperText={getEmailError(errors.email)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={forgotPassword.isLoading}
          >
            Испрати порака за ресетирање на лозинка
          </Button>
        </LoginForm>
      </LoginContainer>
    </PageContainer>
  );
};

export default ForgotPassword;
