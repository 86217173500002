import ContainedBox from "../../../components/common/ContainedBox";
import { Content, Headline } from "../../Faq/components";

const Faq = (): JSX.Element => {

    return (
        <ContainedBox>
            <Headline />
            <Content />
        </ContainedBox >
);
    };

export default Faq;