import axios from "axios";
import { httpPost } from "../http"
import { FILES_ROUTES } from "./routes";


export const uploadFile = async (file: File) => {

    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(process.env.REACT_APP_API_URL + FILES_ROUTES.UPLOAD, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

    const url = response.data.Location;
    return url;
}