import { Box, Icon, IconButton, InputAdornment, TextField } from "@mui/material";
import NavButton from "./NavButton";
import Button from "../Button";
import { memo, useEffect, useState } from "react";
import { useUser } from "../../hooks/user/useUser";
import ProfileButton from "./ProfileButton";

import logo from "./assets/talenti_mk_logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Close, Menu, PeopleAlt } from "@mui/icons-material";
import { User } from "../../api/users/types";
import useBreakpoints from "../../hooks/utils/useBreakpoints";
import useGetConnections from "../../hooks/connections/useGetConnections";
import { GetConnectionsResponse } from "../../api/connections/types";

const LogoLink = styled(Link)`
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.075s ease-out;
  &:hover {
    filter: drop-shadow(0px 0px 2px ${({ theme }) => theme.palette.primary.dark});
  }

  @media screen and (max-width: 768px) {
    height: 50px;
    z-index: 15;
  }

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    width: 100%;
    height: auto;
  }
`;

const LogoImage = styled.img`
  height: 100%;
  @media screen and (min-width: 768px) and (max-width: 1080px) {
    width: 90%;
    height: auto;
  }
`;

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding: 0.5rem clamp(1rem, 5vw, 5rem);
  height: 10vh;
  position: sticky;
  z-index: 5;
  top: 0;
  width: 100vw;

  @media screen and (max-width: 768px) {
    padding: 5px;
    height: 82px;
  }

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    position: fixed;
    align-self: flex-start;
    width: 25vw;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    gap: 1rem;
    z-index: 2;
  }
`;

const NavButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  height: 100%;
  overflow: auto;

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
`;

const SearchBar = ({
  handleSearchSubmit,
  searchValue,
  handleSearchChange,
}: {
  handleSearchSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  searchValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <Box sx={{ position: "relative" }}>
    <form onSubmit={handleSearchSubmit}>
      <TextField
        id="search"
        label="Пребарај таленти..."
        variant="outlined"
        InputProps={{
          sx: {
            pr: 0,
            fontSize: "clamp(0.6rem, 1vw, 1rem)",
          },
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                type="submit"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  p: "0.5rem",
                }}
              >
                <Icon>search</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          backgroundColor: "white",
          borderRadius: "5px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
            },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
          "& .MuiFormLabel-root": {
            fontSize: "clamp(0.6rem, 1vw, 1rem)",
          },
          "& label.Mui-focused": {
            color: "common.offWhite",
          },
        }}
        size="small"
        value={searchValue}
        onChange={handleSearchChange}
      />
    </form>
  </Box>
);

const MobileMenuContainer = styled(Box)`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 1rem;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

type MobileMenuProps = {
  user: User | null;
  logout: () => void;
  closeMenu: () => void;
  receivedRequestsCount?: number;
};

const MobileMenu = ({ user, logout, closeMenu, receivedRequestsCount }: MobileMenuProps) => {
  const MobileNavButton = ({
    text,
    link,
    notificationCount,
  }: {
    text: string;
    link: string;
    notificationCount?: number;
  }) => (
    <NavButton
      sx={{
        textAlign: "center",
        width: "100%",
        height: "50px !important",
        position: "relative",
      }}
      text={text}
      link={link}
      isMain
      onClick={closeMenu}
      notificationCount={notificationCount}
    />
  );

  return (
    <MobileMenuContainer>
      {user ? (
        <>
          <Box width="100%" mb="auto" />
          <ProfileButton onLogout={logout} avatarUrl={user.profile?.avatar} onClick={closeMenu} />
          <Button
            variant="contained"
            color="primary"
            to="/posts/create"
            sx={{
              fontSize: "clamp(0.75rem, 1vw, 1rem)",
              textAlign: "center",
              width: "100%",
            }}
            onClick={closeMenu}
          >
            Креирај оглас
          </Button>
        </>
      ) : (
        <MobileNavButton text="Креирај профил" link="/register" />
      )}
      <MobileNavButton text="Мои огласи" link="/posts/mine" />
      <MobileNavButton text="Пронајди талент" link="/posts" />
      <MobileNavButton text="За работници" link="/for-employees" />
      <MobileNavButton text="За работодавци" link="/for-employers" />
      <MobileNavButton text="Што е Таленти.мк?" link="/aboutus" />
      {user && (
        <MobileNavButton
          text="Поврзувања"
          link="/connections"
          notificationCount={receivedRequestsCount}
        />
      )}
      {user ? (
        <Button
          sx={{ marginTop: "auto", marginBottom: "100px" }}
          fullWidth
          variant="contained"
          color="error"
          onClick={logout}
        >
          Одјави се
        </Button>
      ) : (
        <>
          <NavLink to="/login" style={{ textDecoration: "none", width: "100%" }}>
            <Button fullWidth variant="contained" color="primary" onClick={closeMenu}>
              Најави се
            </Button>
          </NavLink>
        </>
      )}
    </MobileMenuContainer>
  );
};

const NavBar = () => {
  const { user, logout } = useUser();

  const { data } = useGetConnections();
  const requests = data as unknown as GetConnectionsResponse; // TODO: fix BE response
  const receivedRequestsCount = requests?.received?.length;

  const { breakpoint } = useBreakpoints();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchValue === "") return;
    navigate({
      pathname: "/posts",
      search: `?s=${searchValue}`,
    });
    searchValue && setSearchValue("");
  };

  return (
    <NavBarContainer>
      <LogoLink to="/">
        <LogoImage src={logo} alt="logo" />
      </LogoLink>

      {mobileMenuOpen && breakpoint === "mobile" && (
        <MobileMenu
          user={user}
          logout={logout}
          closeMenu={() => setMobileMenuOpen(false)}
          receivedRequestsCount={receivedRequestsCount}
        />
      )}

      {breakpoint !== "mobile" ? (
        <NavButtonsContainer>
          {breakpoint === "tablet" && (
            <Box sx={{ marginTop: "auto", display: "flex", flexDirection: "column", gap: "10px" }}>
              <SearchBar
                handleSearchSubmit={handleSearchSubmit}
                searchValue={searchValue}
                handleSearchChange={handleSearchChange}
              />
              {user && (
                <Button fullWidth variant="contained" color="error" onClick={logout}>
                  Одјави се
                </Button>
              )}
            </Box>
          )}
          <NavButton text="Пронајди талент" link="/posts" />
          <NavButton text="За работници" link="/for-employees" />
          <NavButton text="За работодавци" link="/for-employers" />
          <NavButton text="Што е Таленти.мк?" link="/aboutus" />
          {breakpoint === "desktop" && (
            <SearchBar
              handleSearchSubmit={handleSearchSubmit}
              searchValue={searchValue}
              handleSearchChange={handleSearchChange}
            />
          )}
          {user && (
            <NavButton
              text="Поврзувања"
              link="/connections"
              notificationCount={receivedRequestsCount}
              icon={
                breakpoint === "desktop" ? (
                  <PeopleAlt
                    sx={{
                      color: (theme) => theme.palette.secondary.contrastText,
                      "&:hover": {
                        color: (theme) => theme.palette.primary.main,
                      },
                    }}
                  />
                ) : undefined
              }
            />
          )}
          {user && breakpoint === "tablet" && <NavButton text="Мои огласи" link="/posts/mine" />}

          {user ? (
            <>
              <Button
                variant="contained"
                color="primary"
                to="/posts/create"
                sx={{
                  fontSize: "clamp(0.6rem, 1vw, 1rem)",
                  textAlign: "center",
                  "@media screen and (min-width: 768px) and (max-width: 1080px)": {
                    width: "100%",
                  },
                }}
              >
                Креирај оглас
              </Button>
              <ProfileButton onLogout={logout} avatarUrl={user.profile?.avatar} />
            </>
          ) : (
            <>
              <NavButton text="Креирај профил" link="/register" />
              <Button
                variant="contained"
                color="primary"
                to="/login"
                sx={{ fontSize: "clamp(0.6rem, 1vw, 1rem)", textAlign: "center" }}
                {...(breakpoint === "tablet" && { fullWidth: true })}
              >
                Најави се
              </Button>
            </>
          )}
        </NavButtonsContainer>
      ) : (
        <IconButton
          onClick={() => setMobileMenuOpen((v) => !v)}
          color="primary"
          sx={{
            position: "absolute",
            right: "10px",
          }}
        >
          {mobileMenuOpen ? (
            <Close
              sx={{
                fontSize: "40px",
                zIndex: 15,
              }}
            />
          ) : (
            <Menu
              sx={{
                fontSize: "40px",
              }}
            />
          )}
        </IconButton>
      )}
    </NavBarContainer>
  );
};

export default memo(NavBar);
