import Compressor from "compressorjs";

export const convertImageToBase64 = async (file: File | Blob | string): Promise<string> => {
  let fileToCompress: File | Blob;
  if (typeof file === "string") {
    // Create a new File object from the string path location
    const response = await fetch(file);
    const blob = await response.blob();
    fileToCompress = blob;
  } else {
    fileToCompress = file;
  }

  return new Promise<string>((resolve, reject) => {
    new Compressor(fileToCompress, {
      convertTypes: ["jpeg", "webp", "png"],
      convertSize: 100000,
      maxWidth: 500,
      maxHeight: 500,
      resize: "cover",
      quality: 0.2,
      success: (compressedFile) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(compressedFile);
        fileReader.onload = () => {
          resolve(fileReader.result?.toString() || "");
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};
