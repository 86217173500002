import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Content,
  FooterNewsletter,
  Hero,
  SidebarArticles,
  SidebarNewsletter,
  SimilarStories,
} from "./components";
import { useParams } from "react-router";
import { getBlogById } from "../../api/blog";
import { useQuery } from "react-query";
import { Blog } from "../../api/blog/types";
import NewsletterBanner from "../Home/components/NewsletterBanner";

const BlogArticle = (): JSX.Element => {
  const { id } = useParams();
  const { data: blogPost } = useQuery(["blogPost", id], () => getBlogById(id));
  const post = blogPost?.data;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box>
        <Hero {...post} />
        <Box>
          <Grid container paddingX={4} paddingTop={10} spacing={4}>
            <Grid item xs={12} md={8}>
              <Content {...post} />
            </Grid>
            <Grid item xs={12} md={4}>
              {isMd ? (
                <Box marginBottom={4}>
                  <SidebarArticles />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          {/* <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path> */}
        </Box>
      </Box>
      <Box bgcolor={"alternate.main"}>
        {/* <Box>
          <SimilarStories />
        </Box> */}
        <Box
          sx={{
            padding: 4,
          }}
        >
          <NewsletterBanner />
        </Box>
      </Box>
    </Box>
  );
};

export default BlogArticle;
