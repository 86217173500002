import { useQuery } from "react-query";
import { getAcceptedConnections } from "../../api/connections";
import { GetAcceptedConnectionsResponse } from "../../api/connections/types";
import { SuccessResponse } from "../../api/http";

const useGetAcceptedRequests = () =>
  useQuery<SuccessResponse<GetAcceptedConnectionsResponse>>(
    "getAcceptedRequests",
    getAcceptedConnections,
  );

export default useGetAcceptedRequests;
