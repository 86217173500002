import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { register } from "../../api/auth";
import mixpanel from "mixpanel-browser";
export const useRegister = () => {
  const registerMutation = useMutation(register, {
    onSuccess: () => {
      mixpanel.track("Sign Up");
      toast("Успешна регистрација. Ве молиме проверете ја вашата Email адреса.", {
        type: "success",
        position: "bottom-center",
      });
    },
    onError: (error) => {
      console.error(error);
      toast("Обидот за регистрација беше неуспешен. Пробајте повторно.", {
        type: "error",
        position: "bottom-center",
      });
    },
  });

  return registerMutation;
};
