import { Box, Grid, Modal, TextField } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Portfolio } from "../../../../api/posts/types";
import Button from "../../../../components/Button";
import { convertImageToBase64 } from "../../../../utils/base64Utils";
import Portfolios from "../../components/Portfolios";

type PortfolioInputs = Omit<Portfolio, "image"> & {
  image: File | null;
  id: string;
};

type PortfolioFormProps = {
  handleModalClose: () => void;
  append: (v: unknown) => void;
};

const AddPortfolioFormModal = ({ handleModalClose, append }: PortfolioFormProps) => {
  const { register } = useFormContext();

  const {
    control: innerControl,
    handleSubmit,
    reset,
    formState,
    setValue,
    watch,
  } = useForm<PortfolioInputs>({
    defaultValues: {
      title: "",
      description: "",
      url: "",
      image: null,
    },
    mode: "onBlur",
  });

  const handlePortfolioSubmit = async (data: PortfolioInputs) => {
    const imageBase64 = data.image ? await convertImageToBase64(data.image) : "";
    append({
      title: data.title,
      description: data.description,
      url: data.url,
      image: imageBase64,
    });
    reset();
    handleModalClose();
  };

  return (
    <Modal open={true} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxWidth: "95%",
          width: "60%",
          boxSizing: "border-box",
          maxHeight: "100%",
          overflowY: "auto",
        }}
      >
        <h2>Додај </h2>
        <form onSubmit={handleSubmit(handlePortfolioSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Име"
                {...innerControl.register("title", { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Опис"
                multiline
                rows={4}
                {...innerControl.register("description", { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="URL адреса" {...innerControl.register("url")} />
            </Grid>
            <Grid item xs={12}>
              <MuiFileInput
                label="Одбери слика"
                fullWidth
                {...register("image", { required: true })}
                value={watch("image")}
                onChange={(f) => setValue("image", f)}
                onReset={() => setValue("image", null)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formState.isValid}
              >
                Додај
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

const PortfoliosForm = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { control, watch } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    name: "portfolios",
    control,
  });

  const portfolios = watch("portfolios") as Portfolio[];

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Portfolios
        portfolios={portfolios}
        fields={fields}
        handleModalOpen={handleModalOpen}
        handleDelete={(id) => remove(+id)}
        showAddButton
        showDeleteButton
      />
      {modalOpen && (
        <AddPortfolioFormModal
          append={(v: unknown) => append(v)}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default PortfoliosForm;
