import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSearchPosts from "../../hooks/posts/useSearchPosts";
import PageContainer from "../../components/common/PageContainer";
import { useRegions } from "../../hooks/utils/useRegions";
import { useCategories } from "../../hooks/utils/useCategories";

import DropdownSearch from "./components/DropdownSearch";
import { useDebounce } from "@uidotdev/usehooks";
import WorkerCards from "../Home/components/WorkerCards/WorkerCards";
import { Box, CircularProgress, Pagination, Typography } from "@mui/material";
import styled from "styled-components/macro";
import PostFilters from "./components/PostFilters";
import useBreakpoints from "../../hooks/utils/useBreakpoints";
import useSearchPostFilters from "../../hooks/posts/useSearchPostFilters";
import { FiltersResponse } from "../../api/posts/types";
import PostFiltersMobile from "./components/PostFiltersMobile";

const PostsWithPagination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1080px) {
    flex-direction: column-reverse;
  }
`;

const PostsAndFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const Posts = () => {
  const [params, setParams] = useSearchParams();

  const { breakpoint } = useBreakpoints();

  const debouncedSearchText = useDebounce(params.get("s") || "", 1000);
  const category_id = params.get("category_id") as string;
  const region_id = params.get("region_id") as string;
  const salary = params.get("salary") as string;
  const experience = params.get("experience") as string;
  const job_type_id = params.get("job_type_id") as string;
  const has_portfolio = params.get("has_portfolio") as string;
  const has_services = params.get("has_services") as string;

  const [page, setPage] = useState(1);

  const { data: filters, isLoading: filtersIsLoading } = useSearchPostFilters();
  const {
    data: posts,
    refetch,
    isLoading,
  } = useSearchPosts(
    {
      category_id: category_id || undefined,
      region_id: region_id || undefined,
      s: debouncedSearchText || undefined,
      per_page: 12,
      page,
      salary: salary || undefined,
      job_type_id: job_type_id || undefined,
      experience: experience || undefined,
      has_portfolio: has_portfolio || undefined,
      has_services: has_services || undefined,
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [
    debouncedSearchText,
    category_id,
    region_id,
    page,
    salary,
    experience,
    job_type_id,
    has_portfolio,
    has_services,
  ]);

  const { data: regionsData } = useRegions();
  const { data: categoriesData } = useCategories();

  const regions = regionsData?.data;
  const categories = categoriesData?.data;
  const sortedCategories = categories?.sort((a, b) => (a.parent_name > b.parent_name ? 1 : -1));

  const [searchText, setSearchText] = useState(params.get("s") || "");

  const handleSearchTextChange = (value: string) => {
    setSearchText(value);
    setParams((params) => {
      params.set("s", value);
      return params;
    });
  };

  const handleRegionChange = (value: string) => {
    setParams((params) => {
      params.set("region_id", value || "");
      return params;
    });
  };

  const handleCategoryChange = (value: string) => {
    setParams((params) => {
      params.set("category_id", value || "");
      return params;
    });
  };

  const handleJobTypeSelect = (value: string) => {
    setParams((params) => {
      params.set("job_type_id", value || "");
      return params;
    });
  };

  const handlePortfolioSelect = (value: string) => {
    setParams((params) => {
      params.set("has_portfolio", value || "");
      return params;
    });
  };

  const handleServicesSelect = (value: string) => {
    setParams((params) => {
      params.set("has_services", value || "");
      return params;
    });
  };

  if (filtersIsLoading) {
    return (
      <PageContainer>
        <CircularProgress
          sx={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {breakpoint === "desktop" && (
        <DropdownSearch
          regions={regions || []}
          selectedRegionId={params.get("region_id") || undefined}
          selectedCategoryId={params.get("category_id") || undefined}
          // categories={sortedCategories || []}
          searchText={searchText}
          onRegionChange={handleRegionChange}
          onCategoryChange={handleCategoryChange}
          onSearchTextChange={handleSearchTextChange}
        />
      )}
      <PostsAndFiltersContainer>
        {breakpoint === "desktop" ? (
          <PostFilters
            filters={filters?.data || ({} as FiltersResponse)}
            onCategorySelect={handleCategoryChange}
            onRegionSelect={handleRegionChange}
            onJobTypeSelect={handleJobTypeSelect}
            onPortfolioSelect={handlePortfolioSelect}
            onServicesSelect={handleServicesSelect}
          />
        ) : (
          <PostFiltersMobile
            filters={filters?.data || ({} as FiltersResponse)}
            selectedRegionId={params.get("region_id") || undefined}
            onCategorySelect={handleCategoryChange}
            onRegionSelect={handleRegionChange}
            onJobTypeSelect={handleJobTypeSelect}
            onPortfolioSelect={handlePortfolioSelect}
            onServicesSelect={handleServicesSelect}
          />
        )}
        {isLoading ? (
          <CircularProgress
            sx={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : (
          <PostsWithPagination>
            {breakpoint === "mobile" && (
              <Pagination
                count={posts?.meta?.last_page || 0}
                page={page}
                onChange={(_, value) => setPage(value)}
              />
            )}
            <WorkerCards posts={posts?.data || []} />
            {posts?.data && posts?.data.length > 0 ? (
              <Pagination
                count={posts?.meta?.last_page || 0}
                page={page}
                onChange={(_, value) => setPage(value)}
              />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="20px"
                sx={{ height: "100%", width: "100%" }}
              >
                <Typography variant="h4" color="primary">
                  Нема резултати
                </Typography>
                <Typography
                  variant="h6"
                  color="GrayText"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    "&:hover": { color: "black" },
                  }}
                  onClick={() => {
                    setParams((params) => {
                      params.delete("category_id");
                      params.delete("salary");
                      params.delete("experience");
                      params.delete("job_type_id");
                      params.delete("has_portfolio");
                      params.delete("has_services");
                      params.delete("region_id");
                      params.delete("s");
                      return params;
                    });
                  }}
                >
                  Ресетирај ги филтрите
                </Typography>
              </Box>
            )}
          </PostsWithPagination>
        )}
      </PostsAndFiltersContainer>
    </PageContainer>
  );
};

export default Posts;
