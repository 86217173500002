import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Headline = (): JSX.Element => {
  return (
    <Box
      sx={{
        paddingBottom: 1,
      }}
    >
      <Typography
        variant="h3"
        align={"center"}
        fontWeight={700}
        gutterBottom
        sx={{
          letterSpacing: 0,
        }}
      >
        ЧЕСТО ПОСТАВУВАНИ ПРАШАЊА
      </Typography>
    </Box>
  );
};

export default Headline;
