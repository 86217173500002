import { UseQueryOptions, useQuery } from "react-query";
import { searchPosts } from "../../api/posts";
import { SearchPostsPayload, SearchPostsResponse } from "../../api/posts/types";
import { SuccessResponse } from "../../api/http";

type UseSearchPostsParams = SearchPostsPayload;

const useSearchPosts = (
  params: UseSearchPostsParams,
  options?: UseQueryOptions<SuccessResponse<SearchPostsResponse>>,
) =>
  useQuery<SuccessResponse<SearchPostsResponse>>(
    ["searchPosts", params],
    () => searchPosts(params),
    options,
  );

export default useSearchPosts;
