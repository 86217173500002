import styled from "styled-components/macro";
import PageContainer from "../../components/common/PageContainer";
import { Typography } from "@mui/material";

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

const CookiesPolicy = () => (
  <PageContainer>
    <TermsContainer>
      <Typography variant="h2" color="primary">
        Политика за колачиња (cookies)
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Што се всушност колачиња или &lsquo;cookies&rsquo;?</Typography>
        Тоа се мали текстуални програмски сегменти, кои ги преземате на вашиот уред преку кој
        посетувате некоја интернет страна или апликација. Најчесто се користат за да запишат
        одредени корисни информации околу вашата активност или нагодувања (посетени страни, избор на
        јазик, фонтови, бои, светол или темен мод, сложен или прост начин на приказ итн.).
        Колачињата можат да бидат <b>сесиски (&lsquo;привремени&rsquo;)</b> кои се бришат веднаш по
        гасење на вашиот прелистувач или со <b>одреден временски рок</b> во кој остануваат запишани
        на вашиот прелистувач и откако ќе го изгасете.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Што се колачиња од прва страна?</Typography>
        Колачиња од прва страна доаѓаат од веб страници кои ги гледате (во овој случај Talenti.mk),
        а истите може да бидат постојани или привремени. Со помош на тие колачиња, веб страниците
        може да зачувуваат податоци кои повторно ќе се употребат во текот на следната посета на
        истата таа веб страница.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">Што се колачиња од трети страни?</Typography>
        Колачиња од трети страни доаѓаат од реклами од други веб страници (како што се pop-up или
        други реклами) кои се на веб страната што ја гледате. Со помош на тие колачиња, веб
        страниците можат да ја следат Вашата активност на интернет за маркетиншки цели или да
        овозможат најавување преку платформите за социјални медиуми (Google или Facebook).
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">За каква намена ги користиме колачињата на Talenti.mk?</Typography>
        Сесиски колачиња: за инфромации за тековната сесија.
        <br />
        Трајни (временски) колачиња: за статистички бројачи на посети, маркетинг колачиња за приказ
        на реклами, колачиња од социјални медиуми за најавување на сајтот, податок дали да користиме
        колачиња.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Typography variant="h5">
          Како додатно да се заштитите и да ги оневозможите колачињата?
        </Typography>
        Сесиски колачиња: за инфромации за тековната сесија.
        <br />
        Сајтот нуди можност (pop-up прозорец) за одбирање на кои колачиња сакате да ги користите.
        <br />
        Дополнителнот, секој интернет прелистувач (Chrome, Brave, Edge, Safari, IE) има во себе
        повеќе опции за бришење на колачиња. Колачињата моѓете комплетно да ги избришете или само од
        точно одредена страна или автоматски по секоја сесија. Исто така повеќето модерни
        прелистувачи нудат таен/анонимен мод за посета на страни (Incognito/Private/Safe) преку кој
        сите ваши активности и колачиња не се запишуваат.
      </Typography>
      <Typography variant="h5" fontWeight={600}>
        Нашата страна користи HTTPS/SSL протокол за комплетно сигурно прикажување на содржини.
      </Typography>
    </TermsContainer>
  </PageContainer>
);

export default CookiesPolicy;
