import { Grid, TextField, Typography } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import styled from "styled-components/macro";
import { useUser } from "../../hooks/user/useUser";
import { useContact } from "../../hooks/utils/useContact";
import { useForm } from "react-hook-form";
import { ContactPayload } from "../../api/utilities/types";
import Button from "../../components/Button";
import useBreakpoints from "../../hooks/utils/useBreakpoints";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
  align-self: flex-start;
`;

const Contact = () => {
  const { user } = useUser();
  const fullName =
    user?.profile?.first_name && user?.profile?.last_name
      ? `${user?.profile?.first_name} ${user?.profile?.last_name}`
      : "";

  const contact = useContact();

  const { breakpoint } = useBreakpoints();

  const onSubmit = (data: ContactPayload) => contact.mutate(data);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactPayload>({
    defaultValues: {
      name: fullName,
      email: user?.email ?? "",
      message: "",
    },
  });

  return (
    <PageContainer>
      <ContactContainer>
        <Typography variant="h2">Контактирајте не</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register("name", { required: "Внесете име и презиме" })}
                label="Име и презиме"
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("email", { required: "Внесете email" })}
                label="Email адреса"
                variant="outlined"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("message", { required: "Внесете порака" })}
                label="Порака"
                variant="outlined"
                multiline
                rows={5}
                fullWidth
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth={breakpoint !== "desktop"}
                disabled={contact.isLoading}
              >
                Испрати порака
              </Button>
            </Grid>
          </Grid>
        </form>
      </ContactContainer>
    </PageContainer>
  );
};

export default Contact;
