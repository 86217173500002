import { Divider } from "@mui/material";
import styled from "styled-components/macro";

import user_background from "./assets/backgrounds/user.png";
import company_background from "./assets/backgrounds/company.png";
import related_posts_background from "./assets/backgrounds/related_posts.png";

export const StyledDivider = styled(Divider)`
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const PostDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 20px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5px;
    padding-bottom: 50px;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PostAvatarContainer = styled.div<{ type: "user" | "company" }>`
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 10px;
  background-image: url(${({ type }) => (type === "user" ? user_background : company_background)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  position: relative;
  margin-bottom: 75px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const PostAvatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${({ theme }) => theme.palette.common.white};

  @media (min-width: 768px) {
    position: absolute;
    bottom: -75px;
    left: 25px;
  }
`;

export const PostCategoryLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.grey};
`;

export const PostLocation = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

export const PostPersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: clamp(20px, 2vw, 40px);
  align-items: center;
  padding: clamp(10px, 1vw, 20px);
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media (max-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: auto;
    width: 100%;
  }
`;

export const PostPersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PostPersonalInfoIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media (max-width: 767px) {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const StyledListElement = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 5px;
  align-items: center;
`;

export const SkillsAndServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const WorkExperienceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
`;

export const RelatedPostsContainer = styled.div`
  display: flex;
  width: 100vw;
  height: max-content;
  padding: 80px 0px;
  box-sizing: border-box;
  background: url(${related_posts_background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  @media (min-width: 768px) and (max-width: 1080px) {
    margin-left: -37.5vw;
    max-width: 75vw;
  }
  @media (max-width: 767px) {
    margin-left: -50vw;
  }
`;
