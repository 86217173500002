/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import CreateProfile from "./assets/create-profile.png";

const Step1 = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      marginBottom={10}
      sx={{
        background: "#2fcd7130",
        padding: { xs: 2, md: 2 },
        borderRadius: 2,
        paddingY: 0,
        color: "#fff",
        marginTop: { xs: 2, md: 2 },
      }}
    >
      <Grid container spacing={4}>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography variant={"h2"} sx={{ fontWeight: 700, color: "#153B4F" }} gutterBottom>
                Отвори си профил!
              </Typography>
              <Typography variant="h6" letterSpacing={0} lineHeight={"24px"} color="text.secondary">
                Внеси го своето образование, искуство и сите предности кои ќе те истакнат помеѓу
                конуренцијата. Биди креативен во внесување на категории, тагови и отвори си нови
                пазари, позиции и можности. Немаш што да изгубиш!
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component={"img"}
            src={CreateProfile}
            alt="..."
            width={400}
            height={400}
            marginLeft={"calc(60% - 200px)"}
            zIndex={3}
            data-aos={"fade-up"}
            sx={{
              objectFit: "cover",
              filter: theme.palette.mode === "dark" ? "brightness(0.5)" : "none",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step1;
