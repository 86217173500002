import { Box, Divider, IconButton, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import PageContainer from "../common/PageContainer";
import { Facebook, Instagram } from "@mui/icons-material";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
  margin-top: auto;
`;

const UpperFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

const UpperFooterSubMenu = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 20px;
  align-items: flex-start;
  align-self: flex-start;

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    align-items: center;
    align-self: center;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
`;

const PoliciesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

const Footer = () => (
  <PageContainer
    py="50px"
    style={{
      marginTop: "auto",
      backgroundColor: "#153B4F",
      color: "white",
    }}
  >
    <FooterContainer>
      <Divider flexItem />
      <UpperFooterContainer>
        <UpperFooterSubMenu>
          <Box display="flex" gap="20px" alignItems="center">
            <img src="/logo.png" alt="logo" height="25px" />
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <IconButton href="https://www.instagram.com/talenti_mk/" target="_blank">
              <Instagram color="primary" />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/profile.php?id=100094257436775"
              target="_blank"
            >
              <Facebook color="primary" />
            </IconButton>
          </Box>
        </UpperFooterSubMenu>
        <UpperFooterSubMenu>
          <Typography variant="h6">Таленти.мк</Typography>
          <Box>
            <Typography variant="subtitle1">
              <StyledLink to="/for-employees">За работници</StyledLink>
            </Typography>
            <Typography variant="subtitle1">
              <StyledLink to="/for-employers">За работодавци</StyledLink>
            </Typography>
            <Typography variant="subtitle1">
              <StyledLink to="/aboutus">За нас</StyledLink>
            </Typography>
          </Box>
        </UpperFooterSubMenu>
        <UpperFooterSubMenu>
          <Typography variant="h6">Ресурси</Typography>
          <Box>
            <Typography variant="subtitle1">
              <StyledLink to="/blog">Блог</StyledLink>
            </Typography>
            {/* <Typography variant="subtitle1">
              <StyledLink to="/for-employees">Совети за работници</StyledLink>
            </Typography> */}
          </Box>
        </UpperFooterSubMenu>
        <UpperFooterSubMenu>
          <Typography variant="h6">Помош</Typography>
          <Box>
            <Typography variant="subtitle1">
              <StyledLink to="/contact">Контакт</StyledLink>
            </Typography>
            <Typography variant="subtitle1">
              <StyledLink to="/faq">Често поставувани прашања</StyledLink>
            </Typography>
          </Box>
        </UpperFooterSubMenu>
      </UpperFooterContainer>

      <Divider flexItem />
      <TextContainer>
        <Typography variant="subtitle1">
          © Copyright Talenti.mk 2023. All rights reserved
        </Typography>
        <a href="https://fitr.mk" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            sx={{
              height: 50,
              width: "auto",
            }}
            alt="FITR LOGO"
            src="https://talenti-static.s3.amazonaws.com/images/fitr_logo.png"
          />{" "}
        </a>
        <PoliciesContainer>
          <Typography variant="subtitle1">
            <StyledLink to="/privacy-policy">Политика за приватност</StyledLink>
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="subtitle1">
            <StyledLink to="/terms-and-conditions">Услови за користење</StyledLink>
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="subtitle1">
            <StyledLink to="/cookies-policy">Политика за колачиња</StyledLink>
          </Typography>
        </PoliciesContainer>
      </TextContainer>
    </FooterContainer>
  </PageContainer>
);

export default Footer;
