import { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { convertImageToBase64 } from "../../../utils/base64Utils";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import { useFormContext } from "react-hook-form";
import { uploadFile } from "../../../api/files";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AvatarPreview = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || "300px"};
  height: ${({ height }) => height || "300px"};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const AvatarImage = styled.img<{
  isLoading?: boolean;
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;

const PlusSign = styled.div`
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

const RemoveSign = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.error.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.error.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.error.light};
  }
`;

type AvatarUploadProps = {
  avatarBase64: string;
  setAvatarBase64: (avatarBase64: string) => void;
  hasCustomAvatar: boolean;
};

const AvatarUpload = ({ avatarBase64, setAvatarBase64, hasCustomAvatar }: AvatarUploadProps) => {
  const [avatarPreview, setAvatarPreview] = useState<string | null>(avatarBase64 || null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const methods = useFormContext();
  const gender = methods?.watch("gender") ?? "male";

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return setLoading(false);

      const url = await uploadFile(file);
      setAvatarBase64(url);
      // // max 2mb
      // if (file?.size && file.size > 2 * 1024 * 1024) {
      //   toast.error("Максимална големина на сликата е 2MB", { position: "bottom-center" });
      //   return setLoading(false);
      // }

      // if (file) {
      //   const base64 = await convertImageToBase64(file);
      //   setAvatarBase64(base64);
      // }
    } catch (error) {
      toast.error("Грешка при прикачување на сликата", { position: "bottom-center" });
    } finally {
      setLoading(false);
      fileInputRef.current?.value && (fileInputRef.current.value = "");
    }
  };

  const handleRemoveAvatar = () => {
    setAvatarBase64("");
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      setLoading(true);
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    setAvatarPreview(avatarBase64);
  }, [avatarBase64]);

  return (
    <Container>
      <input
        name="avatar"
        accept="image/*"
        style={{ display: "none" }}
        id="avatar-upload"
        type="file"
        onChange={handleImageChange}
        ref={fileInputRef}
      />
      <AvatarPreview>
        <AvatarImage
          isLoading={loading}
          src={avatarPreview || (gender === "male" ? "https://talenti-static.s3.amazonaws.com/avatars/default_avatar_male.png" : "https://talenti-static.s3.amazonaws.com/avatars/default_avatar_female.png")}
          alt="Avatar preview"
        />
        <PlusSign onClick={handleAvatarClick}>+</PlusSign>
        {/* {hasCustomAvatar && <RemoveSign onClick={handleRemoveAvatar}>X</RemoveSign>} */}
      </AvatarPreview>
    </Container>
  );
};

export default AvatarUpload;
