import { CircularProgress } from "@mui/material";
import { useUser } from "../../../hooks/user/useUser";
import CreatePostForm from "./Form";
import PageContainer from "../../../components/common/PageContainer";
import { useCategories } from "../../../hooks/utils/useCategories";
import { useEmploymentStatusTypes } from "../../../hooks/utils/useEmploymentStatusTypes";
import { useRegions } from "../../../hooks/utils/useRegions";
import { toast } from "react-toastify";
import { Navigate } from "react-router";

const CreatePost = () => {
  const { user, isLoading: userLoading } = useUser();

  const { data: employmentStatuses, isLoading: statusesLoading } = useEmploymentStatusTypes();
  const { data: regions, isLoading: regionsLoading } = useRegions();
  const { data: categories, isLoading: categoriesLoading } = useCategories();

  if (userLoading || statusesLoading || regionsLoading || categoriesLoading) {
    return (
      <PageContainer>
        <CircularProgress size={300} thickness={5} />
      </PageContainer>
    );
  }

  if (!user) return <Navigate to="/login" />;

  if (!user.profile) return <Navigate to="/profile" />;

  if (!employmentStatuses?.data || !regions?.data || !categories?.data) {
    toast.error("Ве молиме обидете се повторно.", { position: "bottom-center" });
    return <Navigate to="/" />;
  }

  const userMissingInfo =
    user.user_type === "user" &&
    (!user.profile.first_name ||
      !user.profile.last_name ||
      !user.profile.gender ||
      !user.profile.birthday);

  const companyMissingInfo = user.user_type === "company" && !user.profile.first_name;

  if (userMissingInfo || companyMissingInfo) {
    toast.error(
      "Ве молиме пополнете ги вашите лични податоци во профилот пред да објавите оглас.",
      { position: "bottom-center" },
    );
    return <Navigate to="/profile" />;
  }

  return (
    <CreatePostForm
      user={user}
      employmentStatuses={employmentStatuses?.data}
      regions={regions?.data}
      categories={categories?.data}
    />
  );
};

export default CreatePost;
