import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { useQuery, useQueryClient } from "react-query";
import { getCurrentUser } from "../../api/users";
import { User } from "../../api/users/types";
import { useNavigate } from "react-router";

export const useUser = () => {
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const setUser = userContext.setUser;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    queryClient.resetQueries("connections");
    navigate("/");
  };

  const { refetch, isLoading } = useQuery("user", getCurrentUser, {
    enabled: !!localStorage.getItem("token"),
    onSuccess: (data) => {
      setUser(data as any as User);
    },
    onError: () => {
      setUser(null);
    },
    retry: false,
  });

  return { user, setUser, logout, refetch, isLoading };
};
