import { SxProps, Theme, Typography } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  to?: string;
} & Omit<React.ComponentProps<typeof MuiButton>, "sx"> & {
    sx?: Partial<SxProps<Theme>>;
  };

const StyledButton = styled(MuiButton)`
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, ...props }: ButtonProps, ref) => (
    <StyledButton
      onClick={onClick}
      {...props}
      sx={{
        borderRadius: "8px",
        boxShadow: "none",
        p: "8px 18px",
        fontSize: "16px",
        ...(props.variant === "contained" &&
          props.color === "primary" && {
            color: "common.white",
          }),
        ...(props.sx || {}),
      }}
      LinkComponent={props.to ? NavLink : undefined}
      href={props.to}
      ref={ref}
    >
      <Typography
        sx={{
          fontSize: (props.sx as any)?.fontSize || "initial",
        }}
      >
        {children}
      </Typography>
    </StyledButton>
  ),
);
Button.displayName = "Button";

export default Button;
