import { Divider, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useFieldArray, useFormContext } from "react-hook-form";
import Button from "../../../../components/Button";

const WorkExperienceForm = () => {
  const { register, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "work_experiences",
  });

  return (
    <>
      <Grid container spacing={2} pb={4}>
        {fields.map((field, index) => (
          <Grid item container spacing={2} key={field.id} pb={index === fields.length - 1 ? 0 : 4}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Име на компанија"
                {...register(`work_experiences.${index}.company_name`, { required: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Работна позиција"
                {...register(`work_experiences.${index}.position`, { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Опис на работно искуство"
                {...register(`work_experiences.${index}.description`, { required: true })}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                sx={{
                  width: "100%",
                }}
                format="DD/MM/YYYY"
                label="Датум на започнување"
                defaultValue={watch(`work_experiences.${index}.start_date`) || null}
                {...register(`work_experiences.${index}.start_date`, { required: true })}
                onChange={(date) => setValue(`work_experiences.${index}.start_date`, dayjs(date))}
                disableFuture
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                sx={{
                  width: "100%",
                }}
                format="DD/MM/YYYY"
                label="Краен датум (оставете празно за моментална работа)"
                defaultValue={watch(`work_experiences.${index}.end_date`) || null}
                {...register(`work_experiences.${index}.end_date`)}
                onChange={(date) => setValue(`work_experiences.${index}.end_date`, dayjs(date))}
                disableFuture
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="error" onClick={() => remove(index)}>
                Избриши работно искуство
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => append({})}
            sx={{ minWidth: 300 }}
          >
            Додај претходно работно искуство
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkExperienceForm;
