import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { socialLogin } from "../../api/auth";
import { useUser } from "../user/useUser";

export const useSocialLogin = () => {
  const { refetch } = useUser();
  const loginMutation = useMutation(socialLogin, {
    onSuccess: async ({ data }) => {
      localStorage.setItem("token", data.token);
      refetch();
    },
    onError: (error) => {
      console.error(error);
      toast("Обидот за најава беше неуспешен. Пробајте повторно.", {
        type: "error",
        position: "bottom-center",
      });
    },
  });
  return loginMutation;
};
