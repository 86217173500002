import { Box, TextField, Typography } from "@mui/material";
import { styled } from "styled-components";

import background from "../assets/newsletter_banner_pattern.png";
import Button from "../../../components/Button";
import { useSubscribeToNewsletter } from "../../../hooks/utils/useSubscribeToNewsletter";
import { useForm } from "react-hook-form";

const NewsletterBannerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 250px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border: 10px solid rgba(255, 255, 255, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 23px 59px 0px #1048662b;
  padding: 40px;
  margin-top: 20px;
  gap: 30px;

  @media (max-width: 1080px) {
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }
`;

const MessageContainer = styled(Box)`
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;

  @media (max-width: 1080px) {
    width: 100%;
    align-items: center;
  }
`;

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 20px;
  width: 40%;
  padding: 5px;

  @media (max-width: 1080px) {
    width: 100%;
    align-items: center;
  }
`;

const InputText = styled(Typography)`
  color: white;
  text-align: left;
`;

const NewsletterBanner = () => {
  const subscribe = useSubscribeToNewsletter();

  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data: { email: string }) => {
    subscribe.mutate(data);
    reset();
  };

  return (
    <NewsletterBannerContainer>
      <MessageContainer>
        <Typography
          variant="h4"
          fontWeight="bold"
          color="white"
          sx={{
            "@media (max-width: 1080px)": {
              fontSize: "1.5rem",
            },
          }}
        >
          Остави ни ја твојата мејл адреса и не ги пропуштај новите внесени огласи и работни
          позиции.
        </Typography>
      </MessageContainer>
      <InputContainer onSubmit={handleSubmit(onSubmit)}>
        <InputText variant="body1">
          Со пријавување на нашите известувања го олеснувате и убрзувате процесот на барање на нова
          работа.
        </InputText>
        <TextField
          label="Email адреса"
          fullWidth
          {...register("email", {
            required: "Внесете валидна email адреса",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Внесете валидна email адреса",
            },
          })}
          error={!!formState.errors.email}
          helperText={formState.errors.email?.message}
          InputLabelProps={{
            sx: {
              color: (theme) => theme.palette.common.offBlack,
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "0 5px",
            },
          }}
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                disabled={subscribe.isLoading}
                type="submit"
                sx={{
                  minWidth: "max-content",
                  zIndex: 1,
                }}
              >
                Испрати
              </Button>
            ),
            sx: {
              backgroundColor: "white",
              borderRadius: "5px",
            },
          }}
        />
      </InputContainer>
    </NewsletterBannerContainer>
  );
};

export default NewsletterBanner;
