import React, { useEffect } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Container } from "@mui/material";
import { Blog } from "../../../../api/blog/types";
import dayjs from "dayjs";

const Hero = (post: Blog): JSX.Element => {
  // useEffect(() => {
  //   const jarallaxInit = async () => {
  //     const jarallaxElems = document.querySelectorAll(".jarallax");
  //     if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
  //       return;
  //     }

  //     const { jarallax } = await import("jarallax");
  //     jarallax(jarallaxElems, { speed: 0.2 });
  //   };

  //   jarallaxInit();
  // });

  return (
    <Box
      position={"relative"}
      minHeight={{ xs: 400, sm: 500, md: 600 }}
      display={"flex"}
      alignItems={"center"}
      id="agency__portfolio-item--js-scroll"
    >
      <Box
        className={"jarallax-img"}
        sx={{
          position: "absolute",
          objectFit: "cover",
          /* support for plugin https://github.com/bfred-it/object-fit-images */
          fontFamily: "object-fit: cover;",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: "url(https://assets.maccarianagency.com/backgrounds/img3.jpg)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha("#161c2d", 0.6),
          zIndex: 1,
        }}
      />
      <Box
        position={"relative"}
        sx={{
          width: 1,
        }}
        zIndex={2}
      >
        <Box
          sx={{
            width: 1,
            padding: 3,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              minWidth: "80vw",
              width: 1,
              fontWeight: 400,
              color: "common.white",
              marginBottom: 2,
            }}
          >
            {post.title}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <ListItemText
              sx={{ margin: 0 }}
              primary={post.author}
              secondary={dayjs(post.created_at).format("DD.MM.YYYY")}
              primaryTypographyProps={{
                variant: "h6",
                sx: { color: "common.white" },
              }}
              secondaryTypographyProps={{
                sx: { color: alpha("#ffffff", 0.8) },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
