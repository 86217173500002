import { UseQueryOptions, useQuery } from "react-query";
import { searchCategories } from "../../api/utilities";
import { SearchCategoriesPayload, GetCategoriesResponse } from "../../api/utilities/types";
import { SuccessResponse } from "../../api/http";

type UseSearchCategoriesParams = SearchCategoriesPayload;

const useSearchCategories = (
  params: UseSearchCategoriesParams,
  options?: UseQueryOptions<GetCategoriesResponse>,
) =>
  useQuery<GetCategoriesResponse>(
    ["searchCategories", params],
    async () => {
      const response = await searchCategories(params);
      return response.data;
    },
    options,
  );

export default useSearchCategories;
