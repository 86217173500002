import {
  AccountBalance,
  AccountBalanceWallet,
  Add,
  AttachMoney,
  Edit,
  Info,
  Paid,
  Remove,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Skeleton,
  Typography,
} from "@mui/material";
import styled from "styled-components/macro";
import Button from "../../../../components/Button";
import { Post } from "../../../../api/posts/types";
import { useRegions } from "../../../../hooks/utils/useRegions";
import { Link } from "react-router-dom";
import useSendFriendRequest from "../../../../hooks/connections/useSendFriendRequest";

import { useUser } from "../../../../hooks/user/useUser";
import useGetAcceptedRequests from "../../../../hooks/connections/useGetAcceptedRequests";
import { useMemo, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { EmploymentStatusType } from "../../../../api/utilities/types";
import { useEmploymentStatusTypes } from "../../../../hooks/utils/useEmploymentStatusTypes";
import { toast } from "react-toastify";
import useDeletePost from "../../../../hooks/posts/useDeletePost";
import { getStatus } from "../../../../api/connections";
import { useMutation, useQuery } from "react-query";
import { delay } from "lodash";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: unset !important;
  max-width: 100%;
  // min-width: 300px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #eff7ef !important;
  }
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  min-height: 200px;
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
  height: 100%;
  padding: 8px 0;
  box-sizing: border-box;
  padding-bottom: 0px !important;

  @media (max-width: 360px) {
    padding: 0;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  padding: 0;
`;

const StyledTypography = styled(Typography)``;

const CardFooterContainer = styled.div<{ listItem?: boolean }>`
  display: flex;
  justify-content: ${({ listItem }) => (listItem ? "flex-start" : "space-between")};
  width: 100%;
  gap: ${({ listItem }) => (listItem ? "24px" : "0")};
`;

const FooterInfo = styled(Box) <{
  listItem?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ listItem }) => (listItem ? "auto" : "100%")};
  padding: 8px 0;
  color: ${({ theme }) => theme.palette.common.grey};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
  margin-top: 32px;
`;

const StyledButton = styled(Button) <{
  isOnlyIcon?: boolean;
}>`
  max-height: 100%;
  font-size: 0.9em;
  border-radius: 40px;
  padding: 10px 40px;
  height: 50px;
  box-shadow: none;

  & .MuiTypography-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const trimString = (str: string, length: number) =>
  str.length > length ? str.substring(0, length - 3) + "..." : str;

type WorkerCardProps = {
  post: Post;
  listItem?: boolean;
};

const WorkerCard = ({ post, listItem }: WorkerCardProps) => {
  const { data: regions } = useRegions();
  const [sent, setSent] = useState(false);

  const sendFriendRequest = useSendFriendRequest();

  const deletePost = useDeletePost();

  const handleDeletePost = (postId: number) => {
    deletePost.mutate({ postId: `${postId}` });
    toast("Избришан оглас", {
      type: "info",
      position: "bottom-center",
    });
  };

  const { data: employmentStatuses, isLoading: statusesLoading } = useEmploymentStatusTypes();

  const { user } = useUser();

  const location = regions?.data.find((region) => region.id === post.region_id)?.name;
  const {
    title: name,
    description: summary,
    experience_years: yoe,
    salary: desiredMonthlySalary,
  } = post;

  const { data: connected, isLoading: connectedLoading } = useQuery(
    ["connected", post?.id, user?.id, sent],
    () => getStatus(post?.id ? post?.id.toString() : null, user?.id ? user?.id.toString() : null),
  );

  if (connectedLoading) return <Skeleton variant="rectangular" width={300} height={400} />;

  return (
    <Card
      sx={{
        minWidth: "250px",
        boxShadow: "none",
        paddingX: 1,
        paddingY: 2,
        width: "100%",
        borderRadius: 4,
        border: "1px solid #d0d0d0",
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        <HeaderContainer>
          <ProfilePicture src={post.user?.profile?.avatar ?? "https://talenti-static.s3.amazonaws.com/avatars/default_avatar.png"} alt="Слика" />
          <CardHeader
            style={{ textAlign: "center" }}
            title={
              <Typography
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                {name ?? "Анонимно"}
              </Typography>
            }
            subheader={post.categoryTitle}
          />
        </HeaderContainer>

        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
          }}
        >
          <LocationOnIcon sx={{ fontSize: "1em" }} />
          {location}
        </Typography>
        <Grid container justifyContent={"center"} gap={2}>
          <Grid item>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 14,
              }}
            >
              Очекувана плата
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                backgroundColor: "#218ac230",
                borderRadius: 6,
                paddingY: 0.8,
                pl: 2,
                pr: 2,
                color: "#555555",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                minHeight: 28,
                justifyContent: "center",
              }}
            >
              <AccountBalanceWallet
                fontSize="small"
                sx={{
                  mt: "-1px",
                }}
              />

              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >{`${(+desiredMonthlySalary).toLocaleString("de-DE")}`}</Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: "#777777",
                }}
              >
                {post.salary_currency}
              </Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                color: "#888888",
                textAlign: "center",
              }}
            >
              {post.employment_status &&
                employmentStatuses?.data.find((s) => s.id == post.employment_status)?.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              Работно искуство
            </Typography>
            <StyledTypography
              variant="subtitle2"
              sx={{
                backgroundColor: "#108a0030",
                borderRadius: 6,
                paddingX: 2.5,
                paddingY: 0.8,
                color: "#555555",
                minHeight: 28,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 600,
              }}
            >{`${yoe} години`}</StyledTypography>
          </Grid>
        </Grid>

        <StyledTypography
          variant="body2"
          sx={{
            textAlign: "center",
            marginTop: 1,
          }}
        >
          {listItem ? post.description : trimString(summary, 140)}
        </StyledTypography>
        {/* <CardFooterContainer listItem={listItem}>
            <FooterInfo listItem={listItem}>
              <Icon sx={{ fontSize: "1.5em" }}>location_on</Icon>
              <Typography variant="subtitle1">{location}</Typography>
            </FooterInfo>
            <FooterInfo listItem={listItem}>
              <Icon sx={{ fontSize: "1.5em" }}>money</Icon>
              <Box display="flex">
                <Typography variant="subtitle1" color="secondary">
                  {desiredMonthlySalary}
                </Typography>
                <Typography variant="caption">/месечно</Typography>
              </Box>
            </FooterInfo>
          </CardFooterContainer> */}

        <ButtonsContainer>
          {!user?.id && (
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<Add />}
              to={!user ? "/login" : undefined}
              onClick={() => {
                if (user && post.id) {
                  sendFriendRequest.mutate({ postId: `${post.id}` });
                  delay(() => setSent(true), 300);
                }
              }}
            >
              Поврзи се
            </StyledButton>
          )}
          {connected?.data?.status === "not_connected" && user?.id && (
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<Add />}
              to={!user ? "/login" : undefined}
              onClick={() => {
                if (user && post.id) {
                  sendFriendRequest.mutate({ postId: `${post.id}` });
                  delay(() => setSent(true), 300);
                }
              }}
            >
              Поврзи се
            </StyledButton>
          )}
          {connected?.data?.status === "accepted" && (
            <StyledButton
              color="primary"
              variant="contained"
              disabled
              to={!user ? "/login" : undefined}
            >
              Поврзан
            </StyledButton>
          )}
          {connected?.data?.status === "pending" && (
            <StyledButton
              color="primary"
              variant="contained"
              disabled
              to={!user ? "/login" : undefined}
            >
              Испратена покана
            </StyledButton>
          )}

          <StyledButton
            LinkComponent={Link}
            to={`/posts/${post.id}`}
            variant="outlined"
            color="primary"
            startIcon={<Info />}
          >
            {/* <Info /> */}
            <Typography fontSize="0.8rem">Инфо</Typography>
          </StyledButton>
        </ButtonsContainer>
        {user?.id === post.user_id && (
          <Grid container alignContent={"center"} justifyContent={"center"} paddingTop={1}>
            <Grid item xs={6} alignContent={"center"} justifyContent={"center"}>
              <Button
                sx={{
                  fontSize: "0.9em",
                  borderRadius: "40px",
                }}
                color="secondary"
                variant="contained"
                startIcon={<Edit />}
                to={`/posts/${post.id}/edit`}
              >
                Измени
              </Button>
            </Grid>
            <Grid item xs={6} alignContent={"right"} justifyContent={"right"}>
              <Button
                sx={{
                  fontSize: "0.9em",
                  borderRadius: "40px",
                  float: "right",
                }}
                color="error"
                variant="contained"
                startIcon={<Remove />}
                onClick={() => handleDeletePost(post.id)}
              >
                Избриши
              </Button>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default WorkerCard;
