import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import {
  Hero,
  SearchBox,
  FeaturedArticle,
  LastStories,
  PopularArticles,
  Newsletter,
} from "./components";
import { Container } from "@mui/material";
import ContainedBox from "../../components/common/ContainedBox";
import NewsletterBanner from "../Home/components/NewsletterBanner";

const Blog = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Container disableGutters>
      <Box bgcolor={"alternate.main"} position={"relative"}>
        <Hero />
        {/* <ContainedBox
          sx={{
            marginTop: "-5rem",
            position: "relative",
            zIndex: 3,
            paddingY: "0 !important",
          }}
        >
          <SearchBox />
        </ContainedBox> */}
        {/* <ContainedBox>
          <FeaturedArticle />
        </ContainedBox> */}
        <ContainedBox paddingTop={"0 !important"}>
          <LastStories />
        </ContainedBox>
        {/* <Box paddingBottom={{ xs: 2, sm: 3, md: 4 }}>
          <ContainedBox paddingTop={"0 !important"}>
            <PopularArticles />
          </ContainedBox>
        </Box> */}
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            transform: "translateY(50%)",
            zIndex: 2,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box
        sx={{
          padding: 4,
        }}
      >
        <NewsletterBanner />
      </Box>
    </Container>
  );
};

export default Blog;
