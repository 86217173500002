import { Add } from "@mui/icons-material";
import { Card, CardMedia, CardContent, Box } from "@mui/material";
import styled from "styled-components/macro";
import { Portfolio } from "../../../api/posts/types";
import Button from "../../../components/Button";
import LaunchIcon from '@mui/icons-material/Launch';

const PortfoliosContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 16px;
  width: 100%;
  padding: 10px 0;
`;

const styles = {
  overlay: {
    position: 'absolute',
      top: '20px',
      right: '20px',
      color: 'black',
  } as React.CSSProperties
}

const ConditionalLink = ({ children, href }: { children: React.ReactNode; href?: string }) =>
  href ? (
    <>
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: "none",
        color: "inherit",
        position: 'relative'
      }}
    >
      {children}
    <div style={styles.overlay}><LaunchIcon /></div>

    </a>
    </>
  ) : (
    <>{children}</>
  );

const StyledCard = styled(Card) <{ hoverable?: boolean }>`
  transition: all 0.1s ease-in-out;
  ${({ hoverable, theme }) =>
    hoverable &&
    `
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 5px ${theme.palette.primary.main};
    }
  `}
`;

type PortfoliosProps = {
  portfolios: Portfolio[];
} & (
    | {
      fields: { id: string }[];
      showAddButton: true;
      showDeleteButton: true;
      handleDelete: (id: string) => void;
      handleModalOpen: () => void;
    }
    | {
      fields?: never;
      showAddButton?: false;
      showDeleteButton?: false;
      handleDelete?: never;
      handleModalOpen?: never;
    }
  );

const Portfolios = ({
  portfolios,
  fields,
  showDeleteButton,
  handleDelete,
  showAddButton,
  handleModalOpen,
}: PortfoliosProps) => (
  <PortfoliosContainer>
    {portfolios.map((portfolio, index) => (
      <StyledCard
        key={fields?.[index].id || portfolio.id}
        sx={{ minWidth: 300, mr: 2 }}
        hoverable={!!portfolio.url}
      >
        <ConditionalLink
          href={
            portfolio.url !== ""
              ? portfolio.url.includes("http")
                ? portfolio.url
                : `https://${portfolio.url}`
              : undefined
          }
        >
          {portfolio.image !== "" ? (
            <CardMedia component="img" height="200" image={portfolio.image} alt={portfolio.title} />
          ) : (
            <div
              style={{
                height: 200,
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              }}
            />
          )}
          <CardContent>
            <h3>{portfolio.title}</h3>
            <p>{portfolio.description}</p>
          </CardContent>
        </ConditionalLink>

        {showDeleteButton && (
          <Button
            sx={{ marginTop: "auto" }}
            fullWidth
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete?.(`${index}`);
            }}
          >
            Избриши
          </Button>
        )}
      </StyledCard>
    ))}
    {showAddButton && (
      <Box sx={{ minWidth: 300, width: "100%" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleModalOpen}
          sx={{ height: "100%", minWidth: 300 }}
        >
          <Add sx={{ display: "flex", margin: "auto" }} />
        </Button>
      </Box>
    )}
  </PortfoliosContainer>
);

export default Portfolios;
