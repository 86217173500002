import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { declineFriendRequest } from "../../api/connections";

const useDeclineRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(declineFriendRequest, {
    onSuccess: () => {
      toast.success("Успешно одбиена покана за поврзување!",{position: "bottom-center"});
      queryClient.invalidateQueries("connections");
    },
  });
};

export default useDeclineRequest;
