import { Box, Typography } from "@mui/material";
import styled from "styled-components/macro";
import PopularSearch from "./PopularSearch";
import { usePopularCategories } from "../../../../hooks/utils/useCategories";
import { GetCategoriesResponse } from "../../../../api/utilities/types";

const PopularSearchesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  gap: ${(props) => props.theme.spacing(3)};
`;

const PopularSearchContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${(props) => props.theme.spacing(1)};
  width: 100%;
  justify-items: center;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const PopularSearches = () => {
  const { data: categories } = usePopularCategories();

  const data = (categories as unknown as GetCategoriesResponse)
    ?.map((item) => ({
      label: item.name,
      value: item.id,
      totalSearches: item.total_searches ?? 0,
      image: item.image
    }))
    .sort((a, b) => b?.totalSearches - a?.totalSearches)
    .slice(0, 12);

  return (
    <PopularSearchesContainer>
      <Typography variant="h3" color="primary.contrastText" fontWeight="600">
        Најактуелни пребарувања
      </Typography>
      <PopularSearchContainer>
        {data?.slice(0,8)?.map((item) => (
          <PopularSearch
            key={item.value}
            label={item.label}
            value={item.value}
            image={item.image}
            totalSearches={item.totalSearches}
          />
        ))}
      </PopularSearchContainer>
    </PopularSearchesContainer>
  );
};

export default PopularSearches;
