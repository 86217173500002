import { useMutation } from "react-query";
import { forgotPassword, resetPassword } from "../../api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export const useForgotPassword = () =>
  useMutation(forgotPassword, {
    onSuccess: () => {
      toast("Доколку вашата адреса е пронајдена, ќе добиете порака за ресетирање на лозинката.", {
        type: "success",
        position: "bottom-center",
      });
    },
    onError: () => {
      toast(
        "Настана грешка при праќање на порака за ресетирање на лозинка. Ве молиме обидете се повторно.",
        {
          type: "error",
          position: "bottom-center",
        },
      );
    },
  });

export const useResetPassword = () => {
  const navigate = useNavigate();
  return useMutation(resetPassword, {
    onSuccess: () => {
      toast("Вашата лозинка беше успешно променета.", {
        type: "success",
        position: "bottom-center",
      });
      navigate("/login");
    },
    onError: () => {
      toast("Настана грешка при промена на лозинка. Ве молиме обидете се повторно.", {
        type: "error",
        position: "bottom-center",
      });
    },
  });
};
