import { useMutation, useQueryClient } from "react-query";
import { acceptFriendRequest } from "../../api/connections";
import { toast } from "react-toastify";

const useAcceptRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(acceptFriendRequest, {
    onSuccess: () => {
      toast.success("Успешно прифатена покана за поврзување!",{position: "bottom-center"});
      queryClient.invalidateQueries("connections");
    },
  });
};

export default useAcceptRequest;
