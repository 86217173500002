import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import PageContainer from "../../components/common/PageContainer";
import { LoginButton, LoginContainer, LoginForm, LoginTextField } from "./styled";
import SocialLoginButtons from "./components/SocialLoginButtons";
import { useRegister } from "../../hooks/auth/useRegister";
import { getEmailError, getPasswordError, getPhoneError } from "./validation";
import { useState } from "react";

type RegisterFormInputs = {
  email: string;
  password: string;
  password_confirmation: string;
  phone: string;
  user_type: "user" | "company";
};

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<RegisterFormInputs>({
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      phone: "",
      user_type: "user",
    },
  });

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const signUp = useRegister();
  const navigate = useNavigate();

  const password = watch("password");
  const userType = watch("user_type");

  const onSubmit = (data: RegisterFormInputs) => {
    signUp.mutate(data, {
      onSuccess: () => {
        navigate("/");
      },
    });
  };

  return (
    <PageContainer py="0">
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="body1">
            Креирај профил за да добиеш пристап до сите содржини на нашиот сајт, да објавуваш
            коментари или да го уредуваш твојот профил.
          </Typography>
          <LoginTextField
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            label="Email адреса"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.email}
            helperText={getEmailError(errors.email)}
          />
          <LoginTextField
            {...register("password", { required: true, minLength: 8 })}
            label="Лозинка"
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            error={!!errors.password}
            helperText={getPasswordError(errors.password)}
          />
          <LoginTextField
            {...register("password_confirmation", {
              required: true,
              minLength: 8,
              validate: (value) => value === password,
            })}
            label="Потврди лозинка"
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            error={!!errors.password_confirmation}
            helperText={getPasswordError(errors.password_confirmation)}
          />
          <LoginTextField
            {...register("phone", { required: true, pattern: /\+?[0-9]+/i })}
            label="Телефонски број"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.phone}
            helperText={getPhoneError(errors.phone)}
          />
          <FormControl fullWidth error={!!errors.user_type} margin="normal">
            <InputLabel id="user-type-label">Тип на корисник</InputLabel>
            <Select
              labelId="user-type-label"
              label="Тип на корисник"
              id="user-type"
              {...register("user_type", { required: true })}
              value={userType}
              sx={{
                fieldset: {
                  borderRadius: "16px",
                },
              }}
            >
              <MenuItem value="user">Физичко лице</MenuItem>
              <MenuItem value="company">Правно лице</MenuItem>
            </Select>
            {errors.user_type && <Typography color="error">Полето е задолжително</Typography>}
          </FormControl>
          <Typography variant="subtitle1">
            Веќе имаш профил?{" "}
            <Link component={NavLink} to="/login" color="secondary">
              Најави се
            </Link>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Checkbox
              checked={hasAcceptedTerms}
              onChange={(e) => setHasAcceptedTerms(e.target.checked)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography variant="subtitle1">
              Се согласувам со{" "}
              <Link component={NavLink} to="/terms-and-conditions" color="secondary">
                Условите за користење
              </Link>{" "}
              и{" "}
              <Link component={NavLink} to="/privacy-policy" color="secondary">
                Политиката за приватност
              </Link>
              .
            </Typography>
          </Box>
          <LoginButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={signUp.isLoading || !hasAcceptedTerms}
          >
            Креирај профил
          </LoginButton>
          <SocialLoginButtons />
        </LoginForm>
      </LoginContainer>
    </PageContainer>
  );
};

export default Register;
