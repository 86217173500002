import { Box, TextField } from "@mui/material";
import styled from "styled-components/macro";
import Button from "../../components/Button";
import background from "../../components/common/assets/background.png";

export const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${background});
  background-size: cover;
  padding: 40px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

export const LoginForm = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 35px 75px;
  box-sizing: border-box;
  border-radius: 15px;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    padding: 20px;
    width: 90%;
  }
`;

export const LoginButton = styled(Button)`
  width: 100%;
  border-radius: 90px;
  font-weight: 800;
  color: ${(props) => props.theme.palette.common.white};

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

export const LoginTextField = styled(TextField)`
  fieldset {
    border-radius: 15px;
  }
`;

export const LoginSocialButtonsContainer = styled(Box)`
  display: grid;
  // grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
