import axios, { AxiosRequestConfig } from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export type SuccessResponse<T> = {
  message?: string;
  meta?: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    first_page: number;
    first_page_url: string;
    last_page_url: string;
    next_page_url: string;
    previous_page_url: string;
  };
} & {
  data: T;
};

export type ErrorResponse = {
  message: string;
} & {
  errors: { [key: string]: string }[];
};

const http = async <T, R>(
  method: string,
  url: string,
  data?: T,
  queryParams?: Record<string, unknown>,
  auth = false,
): Promise<SuccessResponse<R>> => {
  try {
    const token = localStorage.getItem("token");
    if (auth && !token) throw new Error("No Auth Token found");
    const headers = auth
      ? {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      : {};

    const config: AxiosRequestConfig = {
      method,
      url: `${baseURL}${url}`,
      params: queryParams,
      data,
      headers,
    };

    const response = await axios(config);


    return response.data as SuccessResponse<R>;
  } catch (e: unknown) {
    throw e as ErrorResponse;
  }
};

export const httpGet = async <R>(
  url: string,
  queryParams?: Record<string, unknown>,
  auth = false,
): Promise<SuccessResponse<R>> => await http("get", url, undefined, queryParams, auth);

export const httpAuthGet = async <R>(
  url: string,
  queryParams?: Record<string, unknown>,
): Promise<SuccessResponse<R>> => await httpGet(url, queryParams, true);

export const httpPost = async <T, R>(
  url: string,
  data: T,
  auth = false,
): Promise<SuccessResponse<R>> => await http("post", url, data, undefined, auth);

export const httpAuthPost = async <T, R>(url: string, data: T): Promise<SuccessResponse<R>> =>
  await httpPost(url, data, true);

export const httpPut = async <T, R>(
  url: string,
  data: T,
  auth = false,
): Promise<SuccessResponse<R>> => await http("put", url, data, undefined, auth);

export const httpAuthPut = async <T, R>(url: string, data: T): Promise<SuccessResponse<R>> =>
  await httpPut(url, data, true);

export const httpDelete = async <R>(url: string, auth = false): Promise<SuccessResponse<R>> =>
  await http("delete", url, undefined, undefined, auth);

export const httpAuthDelete = async <R>(url: string): Promise<SuccessResponse<R>> =>
  await httpDelete(url, true);
