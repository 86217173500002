import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import styled from "styled-components/macro";

// import Container from 'components/Container';
import {
  Hero,
  Pricings,
  Step1,
  Step2,
  Step3,
} from './components';
import { Container } from '@mui/material';
import NewsletterBanner from '../Home/components/NewsletterBanner';
import CTA from './components/CTA';
// import Container from '../../components/Container';

const Employers = (): JSX.Element => {
  const theme = useTheme();

  return (
    <div>
      <Container disableGutters sx={{
        backgroundColor: "transparent"
      }}>
        <Box
          bgcolor={'alternate.main'}
          sx={{
            position: 'relative',
            '&::after': {
              position: 'absolute',
              content: '""',
              width: '30%',
              zIndex: 1,
              top: 0,
              left: '5%',
              height: '100%',
              backgroundSize: '18px 18px',
              backgroundImage: `radial-gradient(${alpha(
                theme.palette.primary.dark,
                0.4,
              )} 20%, transparent 20%)`,
              opacity: 0.2,
            },
          }}
        >
          <Box position={'relative'} zIndex={3}>
            <Hero />
          </Box>
        </Box>
      </Container>
      <Container sx={{
        backgroundColor: "transparent",
        marginY: 10,
      }}>

        <Step1 />
        <Step2 />
        <Step3 />
        <CTA />
      </Container>


    </div>
  );
};

export default Employers;
