import styled from "styled-components/macro";

import { Box, Typography } from "@mui/material";
import Button from "../../../components/Button";
import PageContainer from "../../../components/common/PageContainer";
import background from "../assets/home_greeting_background.png";
import business_people from "../assets/business_people.png";
import { ComponentProps } from "react";

const GreetingContainer = styled.div`
  width: 100vw;
  height: 90vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    width: 75vw;
  }

  @media screen and (max-width: 1080px) {
    height: auto;
  }
`;

const GreetingContentContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const TypeContainer = styled.div<{
  variant?: "user" | "company";
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: ${({ theme, variant }) =>
    variant === "user" ? theme.palette.primary.main : theme.palette.secondary.main};
  text-transform: uppercase;
  letter-spacing: 10px;
  padding-right: 10px;
  color: white;
  transition: background-color 0.3s ease;

  @media screen and (max-width: 1080px) {
    background-color: white;
    color: ${({ theme, variant }) =>
    variant === "user" ? theme.palette.primary.main : theme.palette.secondary.main};
  }
`;

const LargeButton = styled(Button) <
  {
    type?: "user" | "company";
  } & ComponentProps<typeof Button>
>`
  padding: 16px 30px;
  width: max-content;
  font-weight: 700;

  @media screen and (max-width: 1080px) {
    padding: 10px;
    width: 50%;
    color: ${({ theme, type }) =>
    type === "user" ? theme.palette.primary.main : theme.palette.secondary.main};
    background-color: white;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const SubText = styled(Typography)`
  transition: color 0.3s ease;
  @media screen and (max-width: 1080px) {
    color: white;
  }
`;

const GreetingTextContainer = styled.div<{
  variant?: "left" | "right";
}>`
  position: relative;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: ${({ variant }) => (variant === "left" ? "5vw" : "2vw")};
  padding-right: ${({ variant }) => (variant === "right" ? "5vw" : "2vw")};
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-sizing: border-box;
  @media screen and (min-width: 1080px) {
    align-items: ${({ variant }) => (variant === "left" ? "flex-start" : "flex-end")};
    text-align: ${({ variant }) => (variant === "left" ? "left" : "right")};
    border-left: ${({ variant }) => (variant === "left" ? "0" : "1px dotted black")};
    border-right: ${({ variant }) => (variant === "right" ? "0" : "1px dotted black")};
    color: ${({ variant }) => variant === "left" ? "#153b4f" : "#108a00"};

    transition: background-position 0.3s ease, color 0.3s ease;
    background-size: 100% 200%;
    background-position: 0 10%;
    background-image: linear-gradient(
      to bottom,
      transparent 50%,
      ${({ theme, variant }) =>
    variant === "left" ? theme.palette.secondary.main : theme.palette.primary.main}
        50%
    );

    &:hover {
      background-position: 0 100%;
      color: white;

      ${LargeButton}, ${TypeContainer} {
        background-color: white;
        color: ${({ theme, variant }) =>
    variant === "left" ? theme.palette.secondary.main : theme.palette.primary.main};

        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }
      }

      ${SubText} {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  @media screen and (max-width: 1080px) {
    background-color: ${({ theme, variant }) =>
    variant === "left" ? theme.palette.secondary.main : theme.palette.primary.main};
    color: white;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
  }
`;

GreetingTextContainer.defaultProps = {
  variant: "left",
};

const GreetingImage = styled.img`
  position: absolute;
  width: 35%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const GreetingElementsContainer = styled.div<{
  variant?: "left" | "right";
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ variant }) => (variant === "left" ? "flex-start" : "flex-end")};
  gap: 20px;

  @media screen and (max-width: 1080px) {
    min-height: auto;
    height: auto;
    align-items: center;
    width: 100%;
  }
`;

GreetingElementsContainer.defaultProps = {
  variant: "left",
};

type GreetingProps = {
  showRegisterButton?: boolean;
  onSearch?: (location: string, profession: string) => void;
};

const Greeting = ({ showRegisterButton = true, onSearch }: GreetingProps) => (
  <GreetingContainer>
    <PageContainer py="0" pb="0" h="100%">
      <GreetingContentContainer>
        <GreetingTextContainer>
          <TypeContainer variant="company">
            <Typography variant="h6" fontWeight={700}>
              Работодавци
            </Typography>
          </TypeContainer>
          <GreetingElementsContainer>
            <Typography variant="h2" fontWeight={800} sx={{
              lineHeight: 1
            }}>
              Вработувај квалитетен кадар.
            </Typography>
            <SubText variant="h6" fontWeight={500} color="text.secondary">
              Пронајди го најдоброто за твојот бизнис.
            </SubText>
          </GreetingElementsContainer>
          <GreetingElementsContainer>
            <LargeButton variant="contained" color="secondary" to="/posts">
              <Typography variant="h6" fontWeight={700}>
                Листа на работници
              </Typography>
            </LargeButton>
            <LargeButton variant="contained" color="secondary" to="/for-employers">
              <Typography variant="h6" fontWeight={700}>
                Стани дел од Таленти.мк
              </Typography>
            </LargeButton>
          </GreetingElementsContainer>
        </GreetingTextContainer>
        <GreetingTextContainer variant="right">
          <TypeContainer variant="user">
            <Typography variant="h6" fontWeight={700}>
              Работници
            </Typography>
          </TypeContainer>
          <GreetingElementsContainer variant="right">
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}>
              <Typography variant="h2" fontWeight={800} paddingLeft={5} sx={{
                lineHeight: 1
              }}>
                Работи по твои услови.
              </Typography>
              <SubText variant="h6" fontWeight={500} color="text.secondary">
                Дозволи работата да те најде тебе.
              </SubText>
            </Box>
          </GreetingElementsContainer>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}>
            <GreetingElementsContainer variant="left">
              <LargeButton variant="contained" color="primary" to="/posts/create">
                <Typography variant="h6" fontWeight={700}>
                  Креирај оглас
                </Typography>
              </LargeButton>
            </GreetingElementsContainer>
            <GreetingElementsContainer>
              <LargeButton variant="contained" color="primary" to="/for-employees">
                <Typography variant="h6" fontWeight={700}>
                  Како да се приклучиш?
                </Typography>
              </LargeButton>
            </GreetingElementsContainer>
          </Box>
        </GreetingTextContainer>
        <GreetingImage src={business_people} alt="business people" />
      </GreetingContentContainer>
    </PageContainer>
  </GreetingContainer>
);

export default Greeting;
