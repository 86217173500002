import { Facebook, Google } from "@mui/icons-material";
import { LoginSocialButtonsContainer, LoginButton } from "../styled";
import styled from "styled-components/macro";
import { getSocialRedirectUrl } from "../../../api/auth";
import { toast } from "react-toastify";
import { useState } from "react";

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 20px;
  top: 0;

  @media screen and (max-width: 767px) {
    left: 5px;
  }
`;

const SocialLoginButtons = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (provider: string) => {
    try {
      setLoading(true);
      const url = await getSocialRedirectUrl(provider);
      if (typeof url === "string") {
        window.location.href = url;
      }
    } catch (error) {
      toast("Настана грешка при најава. Обидете се повторно.", {
        type: "error",
        position: "bottom-center",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginSocialButtonsContainer>
      <LoginButton
        disabled={loading}
        variant="contained"
        color="secondary"
        onClick={() => handleClick("google")}
      >
        <IconContainer>
          <Google />
        </IconContainer>
        Google
      </LoginButton>
      {/* <LoginButton
        disabled={loading}
        variant="contained"
        color="secondary"
        onClick={() => handleClick("facebook")}
      >
        <IconContainer>
          <Facebook />
        </IconContainer>
        Facebook
      </LoginButton> */}
      {/* <LoginButton variant="contained" color="secondary" onClick={() => handleClick("linkedin")}>
        <IconContainer>
          <LinkedIn />
        </IconContainer>
        Најави се со LinkedIn
      </LoginButton>
      <LoginButton variant="contained" color="secondary" onClick={() => handleClick("github")}>
        <IconContainer>
          <GitHub />
        </IconContainer>
        Најави се со GitHub
      </LoginButton> */}
    </LoginSocialButtonsContainer>
  );
};

export default SocialLoginButtons;
