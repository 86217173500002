import styled from "styled-components/macro";
import { useCategories } from "../../../hooks/utils/useCategories";
import { useRegions } from "../../../hooks/utils/useRegions";
import { useSearchParams } from "react-router-dom";
import {
  Paper,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Slider,
  Typography,
  Switch,
  Divider,
  Button,
  Drawer,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import useBreakpoints from "../../../hooks/utils/useBreakpoints";
import { useMemo, useRef, useState } from "react";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useEmploymentStatusTypes } from "../../../hooks/utils/useEmploymentStatusTypes";
import SliderFilter from "./SliderFilter";
import { FilterAggregation, FiltersResponse } from "../../../api/posts/types";
import { FilterList } from "@mui/icons-material";
import CategoriesAutocomplete from "./CategoriesAutocomplete";

const PostFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80%;
  padding: 10px 0;
  @media (min-width: 769px) {
    width: 30%;
  }
  gap: 10px;
`;

const FiltersWrapper = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  overflow-y: auto;
`;

const FiltersTitle = styled(FormLabel)<{ open?: boolean }>`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;

  @media (max-width: 1080px) {
    margin-bottom: ${(props) => (props.open ? "8px" : "0")};
  }
`;

type PostFiltersProps = {
  onRegionSelect: (region_id: string) => void;
  onCategorySelect: (category_id: string) => void;
  onJobTypeSelect: (job_type_id: string) => void;
  onPortfolioSelect: (has_portfolio: string) => void;
  onServicesSelect: (has_services: string) => void;
  selectedRegionId?: string;
  filters: FiltersResponse;
  // salaryRange: {
  //   min: number;
  //   max: number;
  // };
};

const PostFiltersMobile = ({
  onRegionSelect,
  onCategorySelect,
  selectedRegionId,
  onJobTypeSelect,
  onPortfolioSelect,
  onServicesSelect,
  filters,
}: PostFiltersProps) => {
  const { breakpoint } = useBreakpoints();
  const [mobileRegionFiltersOpen, setMobileRegionFiltersOpen] = useState(true);
  const [mobileCategoryFiltersOpen, setMobileCategoryFiltersOpen] = useState(true);

  const [params, setParams] = useSearchParams();
  const selectedRegions = params.get("region_id")?.split(",") || [];
  const selectedCategories = params.get("category_id")?.split(",") || [];
  const selectedJobTypes = params.get("job_type_id")?.split(",") || [];
  const selectedHasPortfolio = params.get("has_portfolio") || false;
  const selectedHasServices = params.get("has_services") || false;
  const { data: regionsResponse } = useRegions();
  const { data: categoriesResponse } = useCategories();
  const { data: jobTypesResponse } = useEmploymentStatusTypes();
  const regions = regionsResponse?.data.sort((a, b) => a.name.localeCompare(b.name));
  const categories = categoriesResponse?.data.sort((a, b) => a.name.localeCompare(b.name));
  const jobTypes = jobTypesResponse?.data.sort((a, b) => a.name.localeCompare(b.name));

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const searchParams = useMemo(() => {
    const _entries = params.entries();

    return Object.fromEntries(_entries || []);
  }, [params]);

  const initialSalaryRange: [number, number] = useMemo(() => {
    const salary = params.get("salary");
    if (!salary) {
      return [filters.min_salary.value, filters.max_salary.value];
    }

    const [min, max] = salary.split(",");
    return [parseInt(min), parseInt(max)];
  }, [params]);

  const initialExperienceRange: [number, number] = useMemo(() => {
    const experience = params.get("experience");
    if (!experience) {
      return [filters.min_experience.value, filters.max_experience.value];
    }

    const [min, max] = experience.split(",");
    return [parseInt(min), parseInt(max)];
  }, [params]);

  const marks = [
    {
      value: filters.min_salary.value,
      label: String(filters.min_salary.value),
    },
    {
      value: filters.max_salary.value,
      label: String(filters.max_salary.value),
    },
  ];

  const experienceMarks = [
    {
      value: filters.min_experience.value,
      label: String(filters.min_experience.value),
    },
    {
      value: filters.max_experience.value,
      label: String(filters.max_experience.value),
    },
  ];

  const handleRegionChange = (region: any) => {
    const regionId = region?.id ?? "";
    setParams({ region_id: regionId.toString() });
    onRegionSelect(regionId.toString());
  };

  const handleJobTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const job_type_id = event.target.value;
    const index = selectedJobTypes.indexOf(job_type_id);
    if (index === -1) {
      selectedJobTypes.push(job_type_id);
    } else {
      selectedJobTypes.splice(index, 1);
    }
    const filteredJobTypes = selectedJobTypes?.filter(Boolean).filter((x) => x !== "undefined");
    const retVal =
      (filteredJobTypes.length > 1 ? filteredJobTypes?.join(",") : filteredJobTypes[0]) ?? "";
    setParams({ job_type_id: retVal });
    onJobTypeSelect(retVal);
  };

  const handleCategoryFilter = (categoryId: number | null) => {
    const index = selectedCategories.indexOf(categoryId?.toString() || "");
    if (index === -1) {
      selectedCategories.push(categoryId?.toString() || "");
    } else {
      selectedCategories.splice(index, 1);
    }
    const filteredCategories = selectedCategories?.filter(Boolean).filter((x) => x !== "undefined");
    const retVal =
      (filteredCategories.length > 1 ? filteredCategories?.join(",") : filteredCategories[0]) ?? "";
    setParams({ category_id: retVal });
    onCategorySelect(retVal);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const category_id = event.target.value;
  };

  const handlePortfolioSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const has_portfolio = event.target.checked;
    setParams({ has_portfolio: has_portfolio.toString() });
    onPortfolioSelect(has_portfolio.toString());
  };

  const handleServicesSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const has_services = event.target.checked;
    setParams({ has_services: has_services.toString() });
    onServicesSelect(has_services.toString());
  };

  if (!regions || !categories || !jobTypes) {
    return null;
  }

  return (
    <PostFiltersContainer>
      <Button
        sx={{ justifyContent: "flex-end" }}
        variant="contained"
        color="primary"
        fullWidth={false}
        onClick={toggleDrawer(true)}
      >
        <Typography color={"white"}>
          <FilterList />
          Филтрирај
        </Typography>
      </Button>
      <Drawer
        PaperProps={{
          sx: { width: "90%" },
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            width: "90%",
            paddingY: 2,
            margin: "0 auto",
          }}
        >
          <CategoriesAutocomplete categoryId={0} onChoose={handleCategoryFilter} />

          <Autocomplete
            sx={{ marginTop: 2 }}
            id="region-select"
            options={[
              {
                id: 0,
                name: "Сите",
                description: "",
                image: "",
                parent_id: 0,
              },
              ...regions,
            ]}
            getOptionLabel={(option) => option.name}
            value={regions.find((region) => `${region.id}` === selectedRegionId) ?? null}
            onChange={(event, value) => handleRegionChange(value)} // prints the selected value
            renderInput={(params) => <TextField {...params} label="Град" />}
          />
        </Box>

        <SliderFilter
          title="Плата"
          filterOpen={true}
          marks={marks}
          valueLabelFormat={(value) => `${value} ден.`}
          onThrottledChange={(newRange) => {
            if (Array.isArray(newRange)) {
              setParams({ ...searchParams, salary: newRange.join(",") });
            }
          }}
          min={filters.min_salary.value}
          max={filters.max_salary.value}
          step={1000}
          initialValue={[filters.min_salary.value, filters.max_salary.value]}
        />

        <SliderFilter
          title="Искуство"
          filterOpen={true}
          marks={experienceMarks}
          valueLabelFormat={(value) => `${value} год.`}
          onThrottledChange={(newRange) => {
            if (Array.isArray(newRange)) {
              setParams({ ...searchParams, experience: newRange.join(",") });
            }
          }}
          min={filters.min_experience.value}
          max={filters.max_experience.value}
          step={1}
          initialValue={initialExperienceRange}
        />

        {/* <FiltersWrapper>
        <FiltersTitle open={mobileCategoryFiltersOpen}>Плата</FiltersTitle>
        {breakpoint !== "desktop" && (
          <IconButton
            onClick={() => setMobileCategoryFiltersOpen((prev) => !prev)}
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        )}
        {(breakpoint === "desktop" || mobileCategoryFiltersOpen) && (
          <Slider
            value={salaryRange}
            onChange={handleSalaryRangeChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value} ден.`}
            min={0}
            max={80000}
            step={1000}
            marks={marks}
            sx={{
              "& .MuiSlider-thumb": {
                transition: "none",
              },
              "& .MuiSlider-track": {
                transition: "none",
              },
            }}
          />
        )}
      </FiltersWrapper> */}
        {/* <FiltersWrapper>
        <FiltersTitle open={mobileCategoryFiltersOpen}>Искуство</FiltersTitle>
        {breakpoint !== "desktop" && (
          <IconButton
            onClick={() => setMobileCategoryFiltersOpen((prev) => !prev)}
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        )}
        {(breakpoint === "desktop" || mobileCategoryFiltersOpen) && (
          <>
            <Slider
              value={salaryRange}
              onChange={handleSalaryRangeChange}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value} год.`}
              min={0}
              max={20}
              step={1}
              marks={experienceMarks}
            />
          </>
        )}
      </FiltersWrapper> */}
        <FiltersWrapper>
          <FiltersTitle open={mobileRegionFiltersOpen}>Тип на работа</FiltersTitle>
          {breakpoint !== "desktop" && (
            <IconButton
              onClick={() => setMobileRegionFiltersOpen((prev) => !prev)}
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          )}
          {(breakpoint === "desktop" || mobileRegionFiltersOpen) && (
            <StyledFormControl>
              <FormGroup>
                {jobTypes.map((jobType) => (
                  <FormControlLabel
                    key={jobType.id}
                    control={
                      <Checkbox
                        checked={selectedJobTypes.includes(`${jobType.id}`)}
                        onChange={handleJobTypeChange}
                        value={jobType.id}
                      />
                    }
                    label={jobType.name}
                  />
                ))}
              </FormGroup>
              <Divider sx={{ my: 2 }} />
              <FormGroup>
                <FormControlLabel
                  control={<Switch onChange={handlePortfolioSelect} />}
                  label="Има портфолио"
                />
                <FormControlLabel
                  control={<Switch onChange={handleServicesSelect} />}
                  label="Нуди услуги"
                />
              </FormGroup>
            </StyledFormControl>
          )}
        </FiltersWrapper>
        <Button
          sx={{
            width: "50%",
            margin: "0 auto",
            paddingY: 2,
            marginTop: 3,
          }}
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={toggleDrawer(false)}
        >
          <Typography color={"white"}>Филтрирај</Typography>
        </Button>
      </Drawer>
    </PostFiltersContainer>
  );
};

export default PostFiltersMobile;
