import { httpAuthDelete, httpAuthPost, httpAuthGet, httpAuthPut } from "../http";
import { CONNECTIONS_ROUTES } from "./routes";
import { Friendship, GetAcceptedConnectionsResponse, GetConnectionsResponse } from "./types";

export const getConnections = async () =>
  await httpAuthGet<GetConnectionsResponse>(CONNECTIONS_ROUTES.CONNECTIONS);

export const getStatus = async (postId: string, userId: string) => {
  if (postId && userId) {
    return await httpAuthGet<Friendship>(CONNECTIONS_ROUTES.STATUS(postId), {});
  }

}

export const getAcceptedConnections = async () =>
  await httpAuthGet<GetAcceptedConnectionsResponse>(CONNECTIONS_ROUTES.CONNECTED);

export const sendFriendRequest = async ({ postId }: { postId: string }) =>
  await httpAuthPut(CONNECTIONS_ROUTES.FRIEND_REQUEST(postId), {});

export const removeFriend = async ({ userId }: { userId: string }) =>
  await httpAuthDelete(CONNECTIONS_ROUTES.FRIEND_REQUEST(userId));

export const acceptFriendRequest = async ({ connectionId }: { connectionId: string }) =>
  await httpAuthPost(CONNECTIONS_ROUTES.ACCEPT_REQUEST(connectionId), {});

export const declineFriendRequest = async ({ connectionId }: { connectionId: string }) =>
  await httpAuthPost(CONNECTIONS_ROUTES.DECLINE_REQUEST(connectionId), {});
