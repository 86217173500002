/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import Sales from "./assets/sales.png";
import { Button } from "@mui/material";

const Step1 = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      marginBottom={10}
      sx={{
        background: "#153B4F",
        padding: { xs: 5, md: 10 },
        borderRadius: 2,
        backgroundSize: "400%",
        color: "#fff",
      }}
    >
      <Grid container spacing={2}>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography variant={"h2"} sx={{ fontWeight: 700 }} gutterBottom>
                Огласите може и да продаваат!
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
              >
                Тука огласите не се однесуваат само за барање на вработени, туку и за понуда на
                својот бизнис и услуги преку креирање на оглас.
                <br />
                Со секое креирање на оглас за одредена услуга која Вашата компанија може да ја
                понуди (на пример: изработка на веб-страни, уредување на ентериери, транспорт и сл.)
                имате можност да привлечете нови клиенти, односно да ја зголемите видливоста и
                продажбата.
              </Typography>
            </Box>
            <Button variant="contained" color="primary" href="/posts/create">
              <Typography variant="h6" color={"white"} fontWeight={700}>
                Креирај оглас
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component={"img"}
            src={Sales}
            alt="..."
            width={{ xs: 250, md: 400 }}
            height={{ xs: 250, md: 400 }}
            marginLeft={"calc(60% - 200px)"}
            zIndex={3}
            data-aos={"fade-up"}
            sx={{
              objectFit: "cover",
              filter: theme.palette.mode === "dark" ? "brightness(0.5)" : "none",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step1;
