import { httpAuthDelete, httpAuthGet, httpAuthPost, httpAuthPut, httpGet } from "../http";
import { POSTS_ROUTES } from "./routes";
import {
  CreatePostPayload,
  CreatePostResponse,
  Post,
  SearchPostsPayload,
  SearchPostsResponse,
  FiltersResponse
} from "./types";

export const createPost = (payload: CreatePostPayload) =>
  httpAuthPost<CreatePostPayload, CreatePostResponse>(POSTS_ROUTES.CREATE_POST, payload);

export const updatePost = (payload: CreatePostPayload) =>
  httpAuthPut<CreatePostPayload, CreatePostResponse>(
    POSTS_ROUTES.POST_BY_ID(`${payload.id}`),
    payload,
  );

export const searchPosts = (payload: SearchPostsPayload) =>
  httpGet<SearchPostsResponse>(POSTS_ROUTES.SEARCH_POSTS, payload);

export const searchPostFilters = () => httpGet<FiltersResponse>(POSTS_ROUTES.SEARCH_POST_FILTERS);

export const getPostById = (id: string, authenticated) => {
  if (authenticated) {
    return httpAuthGet<Post>(POSTS_ROUTES.POST_BY_ID(id))
  }
  return httpGet<Post>(POSTS_ROUTES.POST_BY_ID(id))
};
export const getMyPostById = (id: string) => httpAuthGet<Post>(POSTS_ROUTES.POST_BY_ID(id));

export const getMyPosts = () => httpAuthGet<Post[]>(POSTS_ROUTES.MY_POSTS);

export const getRelatedPosts = (id: string) => {
  if (id !== "undefined")
    httpGet<Post[]>(POSTS_ROUTES.RELATED_POSTS(id))
};

export const deletePost = async ({ postId }: { postId: string }) =>
  await httpAuthDelete(POSTS_ROUTES.DELETE_POST(postId));
