/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Success from './assets/success.png';
import { List } from '@mui/material';

const mock = [
  {
    title: 'Поголема видливост',
    subtitle:
      'Со комплетно пополнет профил, компанијата има поголема веројатност да биде забележана од потенцијални таленти или клиенти.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
        />
        <circle cx="12" cy="12" r="3"></circle>
      </svg>
    ),
  },
  {
    title: 'Поголема доверба',
    subtitle:
      'Корисниците повеќе им веруваат на компании со целосни профили, бидејќи деталните информации креираат впечаток на автентичност и професионалност.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"
        />
      </svg>
    ),
  },
  {
    title: 'Полесна достапност',
    subtitle:
      'Поголемото споделување на информации ја прави вашата компанија полесна за комуникација со заинтересираните.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M23 21v-2a4 4 0 0 0-3-3.87 M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2 M16 3.13a4 4 0 0 1 0 7.75"
        />
        <circle cx="9" cy="7" r="4"></circle>
      </svg>
    ),
  },
];
const Step3 = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box marginBottom={10}
      sx={{
        background: "#153B4F",
        padding: { xs: 5, md: 10 },
        borderRadius: 2,
        color: "#fff"
      }}>
      <Grid container spacing={4}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography variant={'h2'} sx={{ fontWeight: 700 }} gutterBottom>
                Креирај профил на компанијата!
              </Typography>
              <Typography color="#FAF9F6">
                Внесете ги сите важни податоци за вашата компанија кои ќе ги привлечат правите работници или клиенти.
                <br />
                Пополнувањето на профилот на вашата компанија до 100% е од суштинска важност за успешната интеракција со талентирани професионалци и клиенти на Таленти.мк.
              </Typography>
              <List disablePadding>
                {mock.map((item, index) => (
                  <ListItem
                    key={index}
                    disableGutters
                    data-aos="fade-up"
                    data-aos-delay={index * 300}
                    data-aos-offset={100}
                    data-aos-duration={600}
                  >
                    <ListItemAvatar>
                      <Box
                        component={Avatar}
                        variant={'rounded'}
                        color={theme.palette.primary.dark}
                        bgcolor={`${theme.palette.primary.light}22`}
                      >
                        {item.icon}
                      </Box>
                    </ListItemAvatar>
                    <ListItemText primary={item.title} secondary={item.subtitle} secondaryTypographyProps={{ style: { color: "#FAF9F6" } }} />
                  </ListItem>
                ))}
              </List>
            </Box>

          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height={1} display={'flex'} flexDirection={'column'}>
            <Box
              component={'img'}
              src={Success}
              alt="..."
              width={{ xs: 250, md: 500 }}
              height={{ xs: 250, md: 500 }}
              marginLeft={{
                xs: 0,
                md: 'calc(60% - 250px)',
              }}
              zIndex={3}
              data-aos={'fade-up'}
              sx={{
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.5)' : 'none',
              }}
            />

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step3;
