import React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon, Link, SxProps, Theme } from "@mui/material";
import styled from "styled-components/macro";
import { theme } from "../../theme/theme";

type NavDropdownItemProps = {
  text: string;
  link: string;
};

type StyledNavTextProps = {
  textColor?: "dark" | "light";
  isMain?: boolean;
  fontSize?: string;
};

const StyledNavText = styled(Typography)<StyledNavTextProps>`
  text-decoration: none;
  color: ${(props) =>
    props.textColor === "dark" ? theme.palette.common.offBlack : theme.palette.common.offWhite};
  font-size: ${(props) => (props.isMain ? "clamp(1rem, 2vw, 1.5rem)" : "clamp(0.6rem, 1vw, 1rem)")};
  font-weight: 500;
  vertical-align: middle;
  line-height: normal;
  height: 100%;
  transition: color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  &:hover {
    color: ${theme.palette.secondary.contrastText};
    cursor: pointer;
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    width: 100%;
    font-size: clamp(1rem, 1.7vw, 1.8rem);
  }
`;

StyledNavText.defaultProps = {
  textColor: "light",
};

const NavButtonContainer = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) and (max-width: 1080px) {
    width: 100%;
    height: 50px;
  }
`;

const NotificationBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.error.main};
  color: ${theme.palette.error.contrastText};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 10px;
  position: absolute;
  top: 15%;
  right: -45%;

  @media (max-width: 768px) {
    top: 30%;
    right: -20%;
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    top: -10%;
    right: 0;
  }
`;

type NavButtonProps = {
  text: string;
  link?: string;
  isDropdown?: boolean;
  dropdownItems?: NavDropdownItemProps[];
  isMain?: boolean;
  sx?: Partial<SxProps<Theme>>;
  notificationCount?: number;
  onClick?: () => void;
  icon?: React.ReactNode;
};

const NavButton = ({
  text,
  link,
  isDropdown = false,
  dropdownItems = [],
  isMain = false,
  sx,
  notificationCount,
  onClick,
  icon,
}: NavButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavButtonContainer
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...(sx as SxProps<Theme>),
      }}
    >
      {icon ? (
        <Link
          component={NavLink}
          to={link ?? "/"}
          onClick={onClick}
          sx={{
            position: "relative",
            textDecoration: "none",
            color: "initial",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {icon}
          {!!notificationCount && <NotificationBadge>{notificationCount}</NotificationBadge>}
        </Link>
      ) : isDropdown ? (
        <>
          <StyledNavText
            variant="button"
            fontSize="clamp(0.8rem, 1.5vw, 1rem)"
            onClick={handleClick}
          >
            {text.toUpperCase()}
            <Icon
              sx={{
                verticalAlign: "middle",
                fontSize: "1rem",
              }}
            >
              arrow_drop_down
            </Icon>
          </StyledNavText>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {dropdownItems.map((item) => (
              <MenuItem
                autoFocus={false}
                selected={false}
                key={item.link}
                component={NavLink}
                to={item.link}
                onClick={handleClose}
              >
                <StyledNavText variant="button" textColor="dark">
                  {item.text.toUpperCase()}
                </StyledNavText>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <StyledNavText
          variant="button"
          as={NavLink}
          to={link ?? "/"}
          isMain={isMain}
          onClick={onClick}
        >
          {text.toUpperCase()}
          {!!notificationCount && <NotificationBadge>{notificationCount}</NotificationBadge>}
        </StyledNavText>
      )}
    </NavButtonContainer>
  );
};

export default NavButton;
