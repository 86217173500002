/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import Success from "./assets/success.png";

const Step3 = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      marginBottom={10}
      marginTop={20}
      paddingY={10}
      sx={{
        background: "#F4AC45",
        padding: { xs: 5, md: 10 },
        borderRadius: 2,
        paddingY: 0,
        color: "#fff",
      }}
    >
      <Grid container spacing={4}>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography variant={"h1"} sx={{ fontWeight: 700 }} gutterBottom>
                Остани анонимен!
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                }}
              >
                Во ова време на многу опции и понуди, ја разбираме важноста на анонимност при премин
                од една во друга компанија/клиент. Наш приоритет е Вашата безбедност на Таленти.мк,
                па затоа гарантираме анонимност.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height={1} width={1} display={"flex"} flexDirection={"column"}>
            <Box
              component={"img"}
              src={Success}
              alt="..."
              width={450}
              height={450}
              marginLeft={"calc(60% - 250px)"}
              zIndex={3}
              borderRadius={"100%"}
              // boxShadow={4}
              data-aos={"fade-up"}
              sx={{
                objectFit: "cover",
                filter: theme.palette.mode === "dark" ? "brightness(0.5)" : "none",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          borderRadius: 2,
          padding: 4,
          color: "#153B4F",
          background: "#fff",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: { xs: 0, md: 3 },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
            }}
          >
            Внесувањето во базата на Таленти е комплетно анонимно и твоите податоци не може да ги
            види никој. Во моментот кога ќе пристигне понуда од одредена фирма или физичко лице за
            поврзување, имаш право да одбереш - дали ќе дозволиш да ти ги види твоите приватни
            податоци или сепак ќе сакаш да останеш анонимен.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} padding={3}>
          <Typography
            sx={{
              fontSize: "1.1rem",
            }}
          >
            Со ова го решаваме стравот дека било кој од моменталната компанија има шанси да дознае
            дека го истражуваш пазарот и си отворен/а за подобри понуди.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step3;
