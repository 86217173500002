import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1080 };

const useBreakpoints = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "mobile");

  return { breakpoint };
};

export default useBreakpoints;
