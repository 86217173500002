import { useMutation } from "react-query";
import { updateUserProfile } from "../../api/users";
import { useUser } from "./useUser";
import { toast } from "react-toastify";

export const useUpdateProfile = () => {
  const { refetch: refetchUser } = useUser();

  return useMutation(updateUserProfile, {
    onSuccess: () => {
      refetchUser();
    },
    onError: () => {
      toast.error("Грешка при ажурирање на профилот.",{position: "bottom-center"});
    },
  });
};
