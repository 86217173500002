import { ComponentsOverrides, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { colors } from "@mui/material";
import { mkMK } from "@mui/material/locale";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    offBlack: string;
    offWhite: string;
    paleGreen: string;
    grey: string;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    lg: false;
    xl: false;
  }
}

export const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1080,
      },
    },
    palette: {
      common: {
        offBlack: "#222325",
        offWhite: "#A9B4CD",
        grey: "#7F879E",
        paleGreen: "#EFFBF4",
      },
      primary: {
        main: "#108a00",
        light: "#44d27e",
        dark: "#2ab965",
        contrastText: "#153B4F",
      },
      secondary: {
        main: "#153B4F",
        light: "#486A80",
        dark: "#0a1013",
        contrastText: "#FFF",
      },
      error: {
        main: "#f44336",
        light: "#e57373",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      warning: {
        main: "#ff9800",
        light: "#ffb74d",
        dark: "#f57c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      info: {
        main: "#2196f3",
        light: "#64b5f6",
        dark: "#1976d2",
        contrastText: "#fff",
      },
      success: {
        main: "#4caf50",
        light: "#81c784",
        dark: "#388e3c",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: "Istok Web, Roboto, sans-serif",
      subtitle1: {
        "@media (max-width: 1280px)": {
          fontSize: "0.8rem",
        },
      },
      subtitle2: {
        "@media (max-width: 1280px)": {
          fontSize: "0.75rem",
        },
      },
      h1: {
        fontWeight: 500,
        fontFamily: "Raleway, Roboto, sans-serif",
        letterSpacing: "2px",

      },
      h2: {
        fontWeight: 500,
        fontFamily: "Raleway, Roboto, sans-serif",
        letterSpacing: "2px",
      },
      h3: {
        fontFamily: "Raleway, Roboto, sans-serif",
        letterSpacing: "2px",
        "@media (max-width: 768px)": {
          fontSize: "1.8rem !important",

        },
      },
      h4: {
        fontFamily: "Istok Web,  Roboto, sans-serif",

      },
      h5: {
        fontFamily: "Istok Web, Roboto, sans-serif",

      },
      h6: {
        fontFamily: "Istok Web, Roboto, sans-serif",
        fontSize: "1.2rem",
        "@media (max-width: 1280px)": {
          fontSize: "1rem",
        },
      },
      body1: {
        fontWeight: 300,
      },
      caption: {
        lineHeight: 2.5,
        "@media (max-width: 1280px)": {
          fontSize: "0.6rem",
        },
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
          },
          containedSecondary: {},
        } as ComponentsOverrides["MuiButton"],
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        } as ComponentsOverrides["MuiInputBase"],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
          input: {
            borderRadius: 5,
          },
        } as ComponentsOverrides["MuiOutlinedInput"],
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: "none",
          },
        } as ComponentsOverrides["MuiCard"],
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: "#153b4f",

          },
        },
      },
    },

  }, mkMK),
  {
    factor: 15,
    breakpoints: ["xs", "sm", "md"],
  },
);
