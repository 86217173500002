import { FieldError } from "react-hook-form";

export const getPasswordError = (errors: FieldError | undefined, isRegister = true) => {
  if (!errors) return "";
  switch (errors.type) {
    case "required":
      return "Password полето е задолжително";
    case "minLength":
      return "Password полето мора да има најмалку 8 карактери";
  }

  return isRegister ? "Password и Confirm Password не се исти" : "";
};

export const getEmailError = (errors: FieldError | undefined) => {
  if (!errors) return "";
  switch (errors.type) {
    case "required":
      return "Email полето е задолжително";
    case "pattern":
      return "Внесете валидна email адреса";
  }

  return "";
};

export const getPhoneError = (errors: FieldError | undefined) => {
  if (!errors) return "";
  switch (errors.type) {
    case "required":
      return "Phone полето е задолжително";
    case "pattern":
      return "Внесете валиден телефонски број";
  }

  return "";
};
