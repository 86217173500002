import { Box, Button, Grid, Typography } from "@mui/material";
import styled from "styled-components/macro";
import Banner from "../../components/Banner";
import PopularSearches from "./components/PopularSearches/PopularSearches";
import PageContainer from "../../components/common/PageContainer";
import Greeting from "./components/Greeting";
import WorkerCards from "./components/WorkerCards/WorkerCards";
import { useUser } from "../../hooks/user/useUser";
import { useNavigate } from "react-router";
import useSearchPosts from "../../hooks/posts/useSearchPosts";
import NewsletterBanner from "./components/NewsletterBanner";
import CompanyBanner from "../../components/CompanyBanner";
import EmployeesBanner from "../../components/EmployeesBanner";
import { Content } from "../Faq/components";
import Faq from "./components/Faq";
import { LastStories } from "../Blog/components";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const HomeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 100%;
  box-sizing: border-box;
`;

const Home = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const { data: posts } = useSearchPosts({
    per_page: 3,
    page: 1,
  });

  return (
    <PageContainer py="0">
      <HomeContainer>
        <Greeting
          showRegisterButton={!user}
          onSearch={(location, profession) =>
            navigate({
              pathname: "/posts",
              search: `?region_id=${location}&category_id=${profession}`,
            })
          }
        />
        <HomeInfoContainer>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={10}>
                <Typography variant="h3" color="primary.contrastText" fontWeight="600">
                  Најнови огласи
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}></Grid>
            </Grid>

            <WorkerCards posts={posts?.data || []} variant="default" isCarousel={true} />
            <a style={{ margin: "0 auto" }} href="/posts">
              <Button
                size={"large"}
                endIcon={
                  <Box
                    component={"svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
              >
                Види ги сите
              </Button>
            </a>
          </Box>
          {/* baner kratok za portfolio */}
          {/* sodrzini (blog) kako na upwork golemoto */}
          {/* kategoriite */}
          {/*  */}
          <EmployeesBanner />

          <LastStories />
          <CompanyBanner />

          <PopularSearches />
          <Faq />

          <NewsletterBanner />
        </HomeInfoContainer>
      </HomeContainer>
    </PageContainer>
  );
};
export default Home;
