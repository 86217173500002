import { Navigate, useLocation } from "react-router";
import { CircularProgress } from "@mui/material";
import PageContainer from "../components/common/PageContainer";
import useVerify from "../hooks/auth/useVerify";
import { useUser } from "../hooks/user/useUser";
import { toast } from "react-toastify";

const VerifyEmailHandler = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const verifyEmail = useVerify();

  const { refetch: refetchUser } = useUser();

  if (!token) {
    return <Navigate to="/login" />;
  }

  verifyEmail.mutate({ token });

  if (verifyEmail.isSuccess) {
    localStorage.setItem("token", verifyEmail.data?.data.token);
    refetchUser();
    toast("Успешна верификација на Email адреса.", {
      type: "success",
      position: "bottom-center",
    });
    return <Navigate to="/" />;
  } else if (verifyEmail.isError) {
    return <Navigate to="/login" />;
  }

  return (
    <PageContainer>
      <CircularProgress />
    </PageContainer>
  );
};

export default VerifyEmailHandler;
