import React from 'react';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

const mock = [
  {
    title: 'Ти избираш!',
    description:
      'Кажи си со која плата си задоволен и очекувај подобра и поплатена понуда, без притоа да си го загрозиш моменталното работно место.\nНе заборавај дека колку повеќе категории внесуваш - толку повеќе опции ти стануваат достапни.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration4.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration4--dark.svg',
    color: colors.blue[200],
  },
  {
    title: 'Издвој се од конкуренцијата!',
    description:
      'За да се издвоиш од конкуренцијата и да станеш еден од најбараните таленти, не те ограничуваме само на една работа и еден оглас. Напротив, препорачуваме да истражиш повеќе работи во кои си добар, да селектираш повеќе твои способности, вештини, категории, тагови, за да ти се отворат повеќе работни позиции и повеќе можности за заработка.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration3.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration3--dark.svg',
    color: colors.purple[200],
  },
  {
    title: 'Различни работни позиции',
    description:
      'Тука можеш да пронајдеш и part-time позиции, за позиции кои можеби не си ни претпоставил дека може да ти донесат плус пари.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration2.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg',
    color: colors.indigo[200],
  },
  {
    title: 'Добиј понуди',
    description:
      'Дозволи компаниите да ги достават своите понуди или да ја прифатат твојата, а притоа ќе скратиш на време, трошоци и нерви одејќи по интервјуа и селекции кои може да траат со месеци.',
    illustration:
      'https://assets.maccarianagency.com/svg/illustrations/illustration1.svg',
    illustrationDark:
      'https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg',
    color: colors.green[200],
  },
];

const Available = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h3"
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Стани достапен за сите домашни и странски компании!
        </Typography>
        <Typography variant="h6" color={'text.secondary'} data-aos={'fade-up'}>
        Кажете му ЗБОГУМ на традиционалното барање работа и ДОБРЕДОЈДЕ на иднината каде што совршената работа ќе ви дојде без никаков напор. 
        <br />
Вашиот следен потег во кариерата е само на момент, седнете, опуштете се и оставете ја работата од соништата да Ве пронајде ВАС.
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 8 : 4}>
        {mock.map((item, i) => (
          <Grid
            key={i}
            item
            xs={12}
            md={6}
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box
              component={Card}
              height={1}              
              boxShadow={0}
              sx={{
                transform: i % 2 === 1 ? { md: 'translateY(80px)' } : 'none',
                backgroundColor: alpha(item.color, 0.2),
                boxShadow: 0,
                '&.MuiPaper-root': {
                  backgroundColor: alpha(item.color, 0.2),
                }
              }}
              
            >
              <CardContent sx={{ padding: 4 }}>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  marginBottom={{ xs: 2, md: 4 }}
                >
                  <Box
                    component={'img'}
                    loading="lazy"
                    sx={{ height: 80 }}
                    src={
                      theme.palette.mode === 'light'
                        ? item.illustration
                        : item.illustrationDark
                    }
                    width={1}
                  />
                </Box>
                <Box>
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Available;
