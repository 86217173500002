import { Typography, Link } from "@mui/material";
import { Link as NavLink } from "react-router-dom";
import useBreakpoints from "../../hooks/utils/useBreakpoints";

type LinkWithIconProps = {
  icon: React.ReactNode;
  text: string;
  to: string;
  hideMobileText?: boolean;
  target?: string;
  rel?: string;
};

const LinkWithIcon = ({
  icon,
  text,
  to,
  target,
  rel,
  hideMobileText,
  ...rest
}: LinkWithIconProps) => {
  const { breakpoint } = useBreakpoints();

  return (
    <Typography variant="subtitle1">
      <Link
        component={NavLink}
        to={to}
        target={target || "_blank"}
        rel={rel || "noopener noreferrer"}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        {...rest}
      >
        {icon}
        {(!hideMobileText || breakpoint !== "mobile") && text}
      </Link>
    </Typography>
  );
};

export default LinkWithIcon;
