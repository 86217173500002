import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Paper,
  Slider,
  Switch,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import styled from "styled-components/macro";
import useBreakpoints from "../../../hooks/utils/useBreakpoints";
import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { filter } from "lodash";

const FiltersWrapper = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
`;

const FiltersTitle = styled(FormLabel)<{ open?: boolean }>`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;

  @media (max-width: 1080px) {
    margin-bottom: ${(props) => (props.open ? "8px" : "0")};
  }
`;

interface SliderFilterProps {
  title: string;
  marks?: { value: number; label: string }[];
  valueLabelFormat?: (value: number) => string;
  onThrottledChange?: (value: number | number[]) => void;
  min?: number;
  max?: number;
  step?: number;
  initialValue: [number, number];
  filterOpen?: boolean;
}

const SliderFilter = ({
  onThrottledChange,
  title,
  marks,
  max,
  min,
  step,
  valueLabelFormat,
  initialValue,
  filterOpen,
}: SliderFilterProps) => {
  const { breakpoint } = useBreakpoints();
  const [mobileCategoryFiltersOpen, setMobileCategoryFiltersOpen] = useState(filterOpen ?? false);
  const [sliderRange, setSliderRange] = useState<[number, number]>(initialValue); // Initial salary range

  const sliderThrottle = useRef<NodeJS.Timeout | null>(null);

  const handleSliderRangeChange = (_event: Event, newRange: number | number[]) => {
    if (Array.isArray(newRange)) {
      setSliderRange(newRange as [number, number]);
      if (sliderThrottle.current) {
        clearTimeout(sliderThrottle.current);
      }

      sliderThrottle.current = setTimeout(() => {
        onThrottledChange?.(newRange);
      }, 500);
    }
  };

  return (
    <FiltersWrapper>
      <FiltersTitle open={mobileCategoryFiltersOpen}>{title}</FiltersTitle>
      {breakpoint !== "desktop" && (
        <IconButton
          onClick={() => setMobileCategoryFiltersOpen((prev) => !prev)}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <ArrowDropDownIcon />
        </IconButton>
      )}
      {(breakpoint === "desktop" || mobileCategoryFiltersOpen) && (
        <Slider
          value={sliderRange}
          onChange={handleSliderRangeChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          min={min}
          max={max}
          step={step}
          marks={marks}
          sx={{
            "& .MuiSlider-thumb": {
              transition: "none",
            },
            "& .MuiSlider-track": {
              transition: "none",
            },
          }}
        />
      )}
    </FiltersWrapper>
  );
};

export default SliderFilter;
