import { Typography } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import { useResetPassword } from "../../hooks/auth/usePasswordUtils";
import { LoginContainer, LoginForm, LoginTextField } from "../Login/styled";
import { useForm } from "react-hook-form";
import { ResetPasswordPayload } from "../../api/auth/types";
import Button from "../../components/Button";
import { getPasswordError } from "../Login/validation";
import { useNavigate, useSearchParams } from "react-router-dom";

type ResetFormData = Omit<ResetPasswordPayload, "token">;

const ResetPassword = () => {
  const resetPassword = useResetPassword();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const onSubmit = (data: ResetFormData) => {
    if (!token) {
      navigate("/");
      return;
    }
    resetPassword.mutate({ ...data, token });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetFormData>({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });
  return (
    <PageContainer py="0">
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4">Ресетирај лозинка</Typography>
          <Typography variant="body1">Внесете ја вашата нова лозинка.</Typography>
          <LoginTextField
            {...register("password", { required: true, minLength: 8 })}
            type="password"
            label="Нова лозинка"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.password}
            helperText={getPasswordError(errors.password)}
          />
          <LoginTextField
            {...register("password_confirmation", { required: true, minLength: 8 })}
            type="password"
            label="Потврди нова лозинка"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.password_confirmation}
            helperText={getPasswordError(errors.password_confirmation)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={resetPassword.isLoading}
          >
            Ресетирај лозинка
          </Button>
        </LoginForm>
      </LoginContainer>
    </PageContainer>
  );
};

export default ResetPassword;
