import { httpGet, httpPost } from "../http";
import {
  RegisterPayload,
  RegisterResponse,
  LoginPayload,
  LoginResponse,
  SocialLoginPayload,
  VerifyEmailPayload,
  VerifyEmailResponse,
  ForgotPasswordPayload,
  ResetPasswordPayload,
} from "./types";
import { AUTH_ROUTES } from "./routes";

export const register = async (data: RegisterPayload) =>
  await httpPost<RegisterPayload, RegisterResponse>(AUTH_ROUTES.REGISTER, data);

export const login = async (data: LoginPayload) =>
  await httpPost<LoginPayload, LoginResponse>(AUTH_ROUTES.LOGIN, data);

export const getSocialRedirectUrl = async (provider: string) =>
  await httpGet<string>(AUTH_ROUTES.SOCIAL_LOGIN_REDIRECT(provider));

export const socialLogin = async (params: SocialLoginPayload) =>
  httpGet<LoginResponse>(AUTH_ROUTES.SOCIAL_LOGIN(params.provider), params);

export const verifyEmail = async (data: VerifyEmailPayload) =>
  await httpPost<VerifyEmailPayload, VerifyEmailResponse>(AUTH_ROUTES.VERIFY_EMAIL, data);

export const forgotPassword = async (data: ForgotPasswordPayload) =>
  await httpPost<ForgotPasswordPayload, void>(AUTH_ROUTES.FORGOT_PASSWORD, data);

export const resetPassword = async (data: ResetPasswordPayload) =>
  await httpPost<ResetPasswordPayload, void>(AUTH_ROUTES.RESET_PASSWORD, data);
